import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import Trusted from '../../components/Trusted/Trusted';
import BrowseStories from '../../components/BrowseCustomerStories/BrowseStories';
import storybanner from '../../assets/home/storybanner.png';
import mikvahester from '../../assets/home/mikvah-ester.svg';
import tmtrusted from '../../assets/home/tmtrusted.png';
import mikvahteaneck from '../../assets/home/mikvah-teaneck.svg';
import cedar from '../../assets/home/cedar.png';
import "./CustomerStories.scss";
import { useTranslation} from 'react-i18next';
const CustomerStories = () => {
    const { t, i18n } = useTranslation();
    const workData = [
        {
            imgsrc: mikvahester,
            counttitle: "Los Angeles, CA",
            description: '300+ visitors',
        },
        {
            imgsrc: tmtrusted,
            counttitle: "Crown Heights, NY",
            description: '520+ visitors',
        },
        {
            imgsrc: cedar,
            counttitle: "Cedarhurst, NY",
            description: '650+ visitors',
        },
        {
            imgsrc: mikvahteaneck,
            counttitle: "Teaneck, NJ",
            description: '870+ visitors',
        }
      ]
    return (
        <>
            <Header />
            <Banner 
                className= "customer-banner-contents"
                meta= ""
                metabtn= "Customer Stories"
                title= "The system behind dozens of successful Mikvahs"
                description1= "Learn how Mikvahs around the world are elevating the Mikvah experience with Mikvahrsvp!"
                description2= "From small Mikvahs in remote towns to mega Mikvahs in populated cities, each Mikvah benefitted from a unique solution that perfectly fit their needs."
                demo={t("CtaStartWeb")}
                contactbtn="contact sales"
                bannerimg= {storybanner}
            />
            <Trusted
                workData= {workData}
            />
            <BrowseStories />
            <Nextlevel 
                className= "Nmikvah-next-level"
                title= "Ready to dive in?"
                description1= "Let’s help you find your perfect solution."
                span1= "So you can relax knowing that your Mikvah is running smoothly."
                span2= "So your staff can relax knowing everything is taken care of."
                span3= "So the women you serve can relax knowing they are getting a top-tier experience optimized for their privacy and comfort."
                btn1= "contact sales"
                btn2= "view plans"
            />
            <Footer />
        </>
    )
}

export default CustomerStories