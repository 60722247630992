import React from 'react';
import { Link } from 'react-router-dom';
import './Management.scss';

const Management = (props) => {
    return (
        <section className={`the-exp-sec backend ${props.className}`}>
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>{props.meta}</span>
                        <h2>{props.title}</h2>
                    </div>
                    <div className="experience-contents">
                        <div className="experience-items">
                            {
                                props.workData.map((item, id) => (
                                    <div className="experience-item" key={id}>
                                        <div className="experience-wrap">
                                            <div className="experience-logo">
                                                <img src={item.imgsrc} alt={item.imgsrc} />
                                            </div>
                                            <div className="experience-details">
                                                <div className="count-number">
                                                    <h4>{item.countNumber}</h4>
                                                </div>
                                                <div className="count-title">
                                                    <h4>{item.counttitle}</h4>
                                                    <p>{item.countdescription} <Link to={props.countlink}><span>{item.countspan}</span></Link> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Management