import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import Rcv from '../../components/RCV/Rcv';
import LeaderShip from '../../components/MikvahLeadership/LeaderShip';
import Mikvahmsg from '../../components/MikvahMessage/Mikvahmsg';
import MikvahGroups from '../../components/MikvahGroups/MikvahGroups';
import pricebanner from '../../assets/home/price-banner.png';
import './About.scss';
import { useTranslation} from 'react-i18next';
const About = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Header />
            <Banner 
                className= "pricing-banner-contents"
                title= "Allowing women across the globe to joyfully partake in the mitzvah of Mikvah"
                demo={t("CtaStartWeb")}
                contactbtn="contact sales"
                bannerimg= {pricebanner}
            />
            <Rcv />
            <Mikvahmsg />
            <LeaderShip />
            <MikvahGroups />
            <Nextlevel 
                className= "pricing-next-level"
                title= "Ready to learn more?"
                description1= "Let’s help you find your perfect solution."
                span1= "So you can relax knowing that your Mikvah is running smoothly."
                span2= "So your staff can relax knowing everything is taken care of."
                span3= "So the women you serve can relax knowing they are getting a top-tier experience optimized for their privacy and comfort."
                btn1= "Contact Sales"
                btn2= "View Plans"
            />
            <Footer />
        </>
    )
}

export default About