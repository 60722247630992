import React from 'react';
import './MediumMikvah.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import Experience from '../../components/MikvahExperience/Experience';
import MikvahAbout from '../../components/MikvahAbout/MikvahAbout';
import mikvahicon from '../../assets/home/m-mikvah.png';
import phone from '../../assets/home/home-phone.png';
import banerchart from '../../assets/home/medium-bannerbg.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import mmabout from '../../assets/home/mmabout.png';
import mmdoted from '../../assets/home/mmdoted.png';
import newmikvah from '../../assets/home/newmikvah.png';
import existingmikvah from '../../assets/home/existingmikvah.png';
import retrofitmikvah from '../../assets/home/retrofitmikvah.png';
import meximg1 from '../../assets/home/meximg1.png';
import meximg2 from '../../assets/home/meximg2new.png';
import meximg3 from '../../assets/home/meximg3new.png';
import meximg4 from '../../assets/home/meximg4new.png';
import meximg5 from '../../assets/home/meximg5new.png';
import pinkdotted from '../../assets/home/pinkdotted.png';
import spinkdotted from '../../assets/home/spinkdotted.png';
import { useTranslation} from 'react-i18next';
const MediumMikvah = () => {

  const { t, i18n } = useTranslation();


  const ExperienceData = [
    {
        title: t("MedWhyBookTitle"),
        description1: t("MedWhyBookBody"),
        imgsrc: meximg1,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-mmikvah-img-sec1"
    },
    {
        title: t("MedWhySchedTitle"),
        description1: t("MedWhySchedBody"),
        imgsrc: meximg2,
        bluedotcirclesrc: pinkdotted,
        reddotcirclesrc: pinkdotted,
        className: "ex-mmikvah-img-sec2"
    },
    {
        title: t("MedWhyAutoTitle"),
        description1: t("MedWhyAutoBody"),
        description2: "",
        imgsrc: meximg3,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-mmikvah-img-sec3"
    },
    {
        title: t("MedWhyGuestTitle"),
        description1: t("MedWhyGuestBody"),
        description2: "",
        imgsrc: meximg4,
        bluedotcirclesrc: pinkdotted,
        reddotcirclesrc: reddotcircle,
        className: "ex-mmikvah-img-sec4"
    },
    {
        title: t("MedWhyStaffTitle"),
        description1: t("MedWhyStaffBody"),
        description2: "",
        imgsrc: meximg5,
        bluedotcirclesrc: spinkdotted,
        reddotcirclesrc: reddotcircle,
        className: "ex-mmikvah-img-sec5"
    },
  ]

  const NavData = [
    {
        eventKey: "first",
        imgsrc: newmikvah,
        title: t("PlanNewTitle"),
        description: t("PlanNewSubtitle")
    },
    {
        eventKey: "second",
        imgsrc: existingmikvah,
        title: t("PlanExistTitle"),
        description: t("PlanExistSubtitle")
    },
    {
        eventKey: "third",
        imgsrc: retrofitmikvah,
        title: t("PlanRetroTitle"),
        description: t("PlanRetroSubtitle")
    }
  ]
  return (
    <>
        <Header />
        <Banner 
          className= "medium-mikvah-banner-contents"
          auto= {mikvahicon}
          meta= {t("MedMikVisitors")}
          metabtn= {t("MedMik")}
          title= {t("MedTitle")}
          description1= {t("MedHeroBody")}
          description2= ""
          demo={t("CtaStart")}
          commit= ""
          trial= ""
          bannerhomeimg= {banerchart}
          bannerimg= {phone}
        />
        <MikvahAbout
          title= {t("MedSolutionTitle")}
          description1= {t("MedSolutionBody")}
          description2= ""
          imgsrc= {mmabout}
          bluedotcirclesrc= {mmdoted}
        />
        <Experience
          meta= {t("MedWhyPretitle")}
          heading= {t("MedWhyTitle")}
          btntext= "GET STARTED!"
          ExperienceData= {ExperienceData}
          className= "mikvah-experience m-mikvah-experience"
        />
        <MikvahPlans 
          span= "FIND THE RIGHT PLAN"
          title= "Where are you starting?"
          NavData= {NavData}
        />
        <Nextlevel 
          className= "mmikvah-next-level"
          title= {t("MedWhyBookTitle")}
          description= {t("MedCtaBodyTop")}
          description1= {t("MedCtaBody")}
          btn1= {t("CtaSignUp")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default MediumMikvah