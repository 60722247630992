import React from 'react';
import './MikvahPlans.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Banner from '../../components/Banner/Banner';
import PlansDetail from './PlansDetail/PlansDetail';
import PlansFeatures from './MikvahPlanFeatures/PlansFeatures';
import { useTranslation } from 'react-i18next';

const MikvahPlans = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
        <Header />
        <Banner 
          className= "mikvah-plans-banner-contents"
          title= {t("PlanTitle")}
          description1= {t("PlanHeroBody")}
        />
        <PlansDetail />
        <PlansFeatures />
        <Footer />
    </>
  )
}

export default MikvahPlans