import React from 'react';
import {Link} from "react-router-dom";
import './ReservationBanner.scss';
import phone from '../../../assets/home/home-phone.png'
import reservation from '../../../assets/home/reservation.png';
import { useTranslation} from 'react-i18next';
const ReservationBanner = (props) => {
    const { t, i18n } = useTranslation();
    const staticText = ' • Get a '

    const ReservationData = [
        {
            title: t("ResTitle"),
            subtitle: t("ResHeroBody"),
            text: t("CtaDemo"),
            nocommit: t("CtaSubNocom") + staticText,
            getlink: "free trial",
            freetrial: " today!",
        }
    ]
  return (
    <>
        <div className="reservation-banner-contents">
            <div className="container">
                <div className="banner-wrap">
                    <div className="banner-header">
                        <div className="banner-header-content">
                            <span className='banner-span'>{t("EssentialsPretitle")}</span>
                            <Link to='/' className='banner-top-btn'>
                                <img src={reservation} alt="reservation" />
                                <span>{t("ResSystem")}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="banner-content">
                        <div className="left-sec">
                            {
                                ReservationData.map((res, id) => (
                                    <div className="left-content">
                                        <h1 className='title'>{res.title}</h1>
                                        <div className="description">
                                            <p>
                                                {res.subtitle}
                                            </p>
                                        </div>
                                        <div className="buttons-sec">
                                            <Link to="#" className="sale-btn">
                                                <span>{res.text}</span>
                                            </Link>
                                        </div>
                                        <div className="commitment">
                                            <p>
                                                {res.nocommit} 
                                                <Link href="/" className='get-link'>{res.getlink}</Link>
                                                {res.freetrial}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="right-sec">
                            <div className="phone-img">
                                <img src={phone} alt="phone" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ReservationBanner