import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import './MikvahTabs.scss';

const MikvahTabs = (props) => {

  return (
    <section className={`mikvah-tabs-sec ${props.className}`}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className='mikvah-tab-row'>
                <Col className='left-menu-col'>
                    <Nav variant="pills" className="nav-wrap">
                        {
                            props.TabsData.map((item, id) => (
                                <Nav.Item key={id}>
                                    <Nav.Link eventKey={item.tabkey}>
                                        <div className="nav-content">
                                            <div className="nav-wrap">
                                                <h4 className="nav-title">
                                                    {item.tabtitle}
                                                </h4>
                                                <p className="nav-description">
                                                    {item.tabdescription}
                                                </p>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            ))
                        }
                        <button className='transparent-button -using-box-models'>
                            <Link to={props.btnlink}>
                                <span>{props.btnname}</span>
                            </Link>
                        </button>
                    </Nav>
                </Col>
                <Col className='right-content-col'>
                    <Tab.Content>
                        {
                            props.TabsPane.map((item, id) => (
                                <Tab.Pane eventKey={item.tabkey} key={id}>
                                    <div className={`wrap-tab ${item.className}`}>
                                        <div className="static-img">
                                            <img src={item.imgsrc} alt={item.imgsrc} />
                                        </div>
                                        <img src={item.bluedotcirclesrc} alt={item.bluedotcirclesrc} className='rotate-item1' />
                                        <img src={item.randombluesrc} alt={item.randombluesrc} className='rotate-item3' />
                                        <img src={item.reddotcirclesrc} alt={item.reddotcirclesrc} className='rotate-item2' />
                                    </div>
                                </Tab.Pane>
                            ))
                        }
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </section>
  )
}

export default MikvahTabs