import React from 'react';
import './DoorControl.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import DoorCapabilities from './DoorCapabilites/DoorCapabilities';
import Banner from '../../components/Banner/Banner';
import Faq from '../../components/FAQs/Faq';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Workmanagement from '../../components/WorkManagement/Management';
import dooricon from '../../assets/home/door-white.png';
import doorbannerimg from '../../assets/home/doorbanner.png';
import user from '../../assets/home/user.png';
import tablock from '../../assets/home/tablock.png';
import { useTranslation} from 'react-i18next';
const DoorControl = () => {
  const { t, i18n } = useTranslation();
  const accordionData = [
    {
        title: t("FullFaq1Title"),
        descriptiontop: t("FullFaq1Body"),
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
        title:  t("FullFaq2Title"),
        descriptiontop: "Absolutely! If you have two or more women using your Mikvah on any given night, this system will greatly benefit you. If you have maybe five women a month, come back to us when you (G-d willing!) grow your attendance.",
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
        title:  t("FullFaq3Title"),
        descriptiontop: t("FullFaq3Body"),
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
        title:  t("FullFaq4Title"),
        descriptiontop: t("FullFaq4Body"),
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
        title:  t("FullFaq5Title"),
        descriptiontop: t("FullFaq5Body"),
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
  ]

  const workData = [
    {
        imgsrc: tablock,
        countNumber: "1.",
        counttitle: t("DoorHowLockTitle"),
        countdescription: t("DoorHowLockBody"),
    },
    {
        imgsrc: user,
        countNumber: "2.",
        counttitle: t("DoorHowTrackTitle"),
        countdescription: t("DoorHowTrackBody")
    }
  ]
  return (
    <>
        <Header />
        <Banner 
          className= "door-control-banner-contents"
          auto= {dooricon}
          meta= "FULL SERVICE PLAN"
          metabtn= "Door Control"
          title= {t("DoorTitle")}
          description1= {t("DoorHeroBody")}
          demo=""
          commit= ""
          trial= ""
          bannerimg= {doorbannerimg}
        />
        <Workmanagement 
          workData= {workData}
          className= "door-management-sec"
          meta= {t("DoorHowPretitle")}
          title= {t("DoorHowTitle")}
        />
        <DoorCapabilities />
        <DiffMikvahs 
          meta= "STILL UNSURE IF FULL SERVICE IS RIGHT FOR YOU?"
          heading= "See how dozens of Mikvahs like yours benefitted from full automation"
          btn= {t("CtaReadCustomerStories")}
        />
        <Faq 
          className= "door-faq"
          accordionData= {accordionData}
        />
        <Nextlevel 
          className= "door-next-level"
          meta= {t("DispCtaPretitle")}
          title= {t("DispCtaTitle")}
          description1= {t("DispCtaBody")}
          span1= ""
          span2= ""
          span3= ""
          btn1= {t('CtaPlan')}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default DoorControl