import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import CloseIcon from '@mui/icons-material/Close';
import checkin from '../../../assets/home/checkin.png';
import newreservation from '../../../assets/home/new-reservation.png';
import reserved from '../../../assets/home/reserved.png';
import managepay from '../../../assets/home/manage-pay.png';
import managepay1 from '../../../assets/home/manage-pay1.png';
import './Reception.scss';
import { useTranslation } from 'react-i18next';

const Reception = () => {

    const {t, i18n} = useTranslation();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    return (
        <section className="reception-sec">
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>{t("DashRecPretitle")}</span>
                        <h2>{t("DashRecTitle")}</h2>
                        <p>
                        {t("DashRecBody")}.
                        </p>
                    </div>
                    <div className="experience-contents">
                        <div className="experience-items">
                            <div className="experience-img">
                                <Button variant="primary" onClick={handleShow} className="popup-btn btn1">
                                    <span>1</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow1} className="popup-btn btn2">
                                    <span>2</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow2} className="popup-btn btn3">
                                    <span>3</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow3} className="popup-btn btn4">
                                    <span>4</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow4} className="popup-btn btn5">
                                    <span>5</span>
                                </Button>

                                {show && (
                                    <div className="custom-modal">
                                        <div className="custom-modal-content">
                                            <div className="close-icon" onClick={handleClose}>
                                                <CloseIcon />
                                            </div>
                                            <h4 className="title">{t("DashRecMapTitle")}.</h4>
                                            <ul>
                                                <li>View rooms at a glance to see how the Mikvah is operating</li>
                                                <li>Live map displays room journey for advanced management</li>
                                                <li>Manually lock doors, change room status, and more</li>
                                                <li>View Mikvah queue and attendants on duty</li>
                                                <li>Timer allows you to keep track of reservation times</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {show1 && (
                                    <div className="custom-modal image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose1}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={checkin} alt="checkin" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DashRecChecTitle")}</h4>
                                                <p className="description">
                                                {t("DashRecChecBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show2 && (
                                    <div className="custom-modal image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose2}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={newreservation} alt="newreservation" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DashRecResTitle")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show3 && (
                                    <div className="custom-modal image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose3}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={reserved} alt="reserved" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DashRecListTitle")}</h4>
                                                <p className="description">
                                                    View upcoming reservations, individual 
                                                    reservations, and any notes or purchases 
                                                    associated with the reservation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show4 && (
                                    <div className="custom-modal image-modal image-modal-col">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose4}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={managepay} alt="managepay" />
                                                <img src={managepay1} alt="managepay1" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DashRecManTitle")}</h4>
                                                <p className="description">
                                                {t("DashRecManBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="booking-options">
                        <h3>{t("DashRecTitle2")}</h3>
                        <div className="d-flex booking-row">
                            <div className="booking-column">
                                <ul>
                                    <li>{t("DashRecBullet1")}</li>
                                    <li>{t("DashRecBullet2")}</li>
                                    <li>{t("DashRecBullet3")}</li>
                                    <li>{t("DashRecBullet4")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                    <li>{t("DashRecBullet5")}</li>
                                    <li>{t("DashRecBullet6")}</li>
                                    <li>{t("DashRecBullet7")}</li>
                                    <li>{t("DashRecBullet8")}</li>
                                    <li>{t("DashRecBullet9")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                    <li>{t("DashRecBullet10")}</li>
                                    <li>{t("DashRecBullet11")}</li>
                                    <li>{t("DashRecBullet12")}</li>
                                    <li>{t("DashRecBullet13")}</li>
                                    <li>{t("DashRecBullet14")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reception