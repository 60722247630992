import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './MikvahAbout.scss';

const MikvahAbout = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className={`mikvah-about-sec ${props.className}`}>
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport quote-wrapper' : 'quote-wrapper'}>
                    <div className="quote-row">
                        <div className="left-quote">
                            <div className="quote-content">
                                <h2 className="quote-title">{props.title}</h2>
                                <p className="quote-description">
                                    {props.description1}
                                </p>
                                <p className="quote-description">
                                    {props.description2}
                                </p>
                                <div className="btn-content">
                                    <Link to='#' className='get-btn'>
                                        <span>{props.btnquote}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="right-quote">
                            <div className="img-sec">
                                <img src={props.imgsrc} alt={props.imgsrc} className='static-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MikvahAbout