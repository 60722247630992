import React from 'react';
import {useNavigate} from "react-router-dom";
import MikvahSlider from '../../../components/Mikvahslider/MikvahSlider';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import storylogo from '../../../assets/home/story-logo.png';
import './CustomerStory.scss';
import { useTranslation } from 'react-i18next';

const CustomerStory = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const goToPage = (page) => {
        navigate(page, { replace: true });
    };
    return (
        <>
            <section className="customer-story-sec">
                <div className="container">
                    <div className="customer-story-wrapper">
                        <div className="top-header">
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={ () => { goToPage('/customer-stories')}}>
                                    Customer Stories 
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active onClick={ () => { goToPage('/')}}>
                                    The Mikvah
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div className="customer-story-content">
                            <div className="customer-story-content-wrap">
                                <div className="left-content">
                                    <div className="story-plans">
                                        <div className="story-logo">
                                            <img src={storylogo} alt="storylogo" />
                                        </div>
                                        <div className="story-items">
                                            <div className="story-item">
                                                <span>Location</span>
                                                <h5>Crown Heights, NY</h5>
                                            </div>
                                            <div className="story-item">
                                                <span>Size</span>
                                                <h5>500+ Monthly Visitors</h5>
                                            </div>
                                            <div className="story-item">
                                                <span>Stage</span>
                                                <h5>New Mikvah</h5>
                                            </div>
                                            <div className="story-item">
                                                <span>PLan</span>
                                                <h5>Full Service</h5>
                                            </div>
                                            <div className="story-item">
                                                <span>Systems used</span>
                                                <ul>
                                                    <li>Reservation System</li>
                                                    <li>Auto Scheduling</li>
                                                    <li>{t("IntDisp")}</li>
                                                    <li>Live Dashboard</li>
                                                    <li>Guidance System</li>
                                                    <li>Door Control</li>
                                                    <li>Mikvah Website</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right-content">
                                    <div className="story-details">
                                        <div className="story-heading">
                                            <h2 className='heading'>Crown Heights's newest Mikvah makes waves for its functionality and ease</h2>
                                        </div>
                                        <div className="story-define-contents">
                                            <p className="description">
                                                The Carroll Street Mikvah, or simply The Mikvah, is Crown Heights's newest Mikvah. From the start of the planning, the knew they wanted to implement the best systems to functionally run the Mikvah day-to-day. They turned to Mikvahrsvp and we worked together to create a custom plan that optimized a faster Mikvah process and visitor privacy.
                                            </p>
                                            <h6 className="required-heading">The Need</h6>
                                            <p className="description">
                                                The Carroll Street Mikvah, or simply The Mikvah, is Crown Heights's newest Mikvah. From the start of the planning, the knew they wanted to implement the best systems to functionally run the Mikvah day-to-day. They turned to Mikvahrsvp and we worked together to create a custom plan that optimized a faster Mikvah process and visitor privacy.
                                            </p>
                                            <div className="main-story-content">
                                                <div className="story-content-bg">
                                                    <div className="story-content-items">
                                                        <p>
                                                            "As a mikvah lady for many years, mikvahrsvp is a refreshing solution for our clients and staff. It’s easy for the women to set up customized appointments, and easy on our end to fulfill the requests.”
                                                        </p>
                                                        <span>
                                                            — Rivka H. <br />
                                                            <span>Mikvah Manager at The Mikvah</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className="required-heading">The Solution</h6>
                                            <p className="description">
                                                The Mikvah opted for the Full Service Plan, going all in on functionality and wanting the best experience for their guests. With door control integration and a guidance system, patrons unlock the front door with their pin, automatically receive the next available prep room, and navigate themselves to the correct room. The process ensures the highest anonymity in line with the heightened sensitivity in the community. 
                                            </p>
                                            <h6 className="required-heading">The Results</h6>
                                            <p className="description">
                                                The Mikvah opted for the Full Service Plan, going all in on functionality and wanting the best experience for their guests. With door control integration and a guidance system, patrons unlock the front door with their pin, automatically receive the next available prep room, and navigate themselves to the correct room. The process ensures the highest anonymity in line with the heightened sensitivity in the community. <br />
                                                "Another, longer quote from someone at the Mikvah"
                                            </p>
                                            <ul>
                                                <li>Higher patron satisfaction</li>
                                                <li>2x faster prep room turnaround</li>
                                                <li>Less staff needed at reception</li>
                                            </ul>
                                        </div>
                                        <MikvahSlider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CustomerStory