import React from 'react';
import Footer from '../../components/NewFooter/Footer';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import CustomerStory from './CustomerStory/CustomerStory';
import MoreStories from './MoreStories/MoreStories';
import './MikvahStory.scss';
import { useTranslation} from 'react-i18next';
const MikvahStory = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <CustomerStory />
            <Nextlevel 
                className= "cmikvah-next-level"
                title= {t("HomeCtaTitle")}
                description1= {t("HomeCtaBody")}
                btn1= "contact sales"
                btn2= "view plans"
            />
            <MoreStories />
            <Footer />
        </>
    )
}

export default MikvahStory