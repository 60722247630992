import React from 'react';
import './GuidanceSystem.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import GuidanceHelp from './GuidanceHelp/GuidanceHelp';
import Banner from '../../components/Banner/Banner';
import guidancebanner from '../../assets/home/guidance-banner.png';
import guidanceicon from '../../assets/home/guidance-white.png';
import { useTranslation } from 'react-i18next';

const GuidanceSystem = () => {

  const {t, i18n} = useTranslation();
  return (
    <>
        <Header />
        <Banner 
          className= "guidance-banner-contents"
          auto= {guidanceicon}
          meta= "FULL SERVICE PLAN"
          metabtn= "Guidance System"
          title= {t("GuidTitle")}
          description1= {t("GuidHeroBody")}
          demo="get a quote"
          commit= ""
          trial= ""
          bannerimg= {guidancebanner}
        />
        <GuidanceHelp />
        <Footer />
    </>
  )
}

export default GuidanceSystem