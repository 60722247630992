import React from 'react';
import Featuresall from '../../../components/FeaturesAll/Features';
import './Features.scss';
import { useTranslation} from 'react-i18next';
const Features = () => {
    
  const { t, i18n } = useTranslation();
  return (
    <>
        <section className="feature-sec">
            <div className="container">
                <div className="feature-wrapper">
                    <div className="heading-sec">
                        <span>{t("HomeProdPretitle")}</span>
                        <h2>{t("HomeProdTitle")}</h2>
                    </div>
                    <Featuresall />
                    <div className="features-buttons">
                        <a href="/" className="btn feature-btn">
                        {t("CtaViewAll")} features
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Features