import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import Experience from '../../components/MikvahExperience/Experience';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import phone from '../../assets/home/home-phone.png';
import banerchart from '../../assets/home/existing-bgimg.jpg';
import egmikvahicon from '../../assets/home/eg-mikvah-icon.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import smikvah from '../../assets/home/s-mikvah.png';
import mmikvah from '../../assets/home/m-mikvah.png';
import lmikvah from '../../assets/home/l-mikvah.png';
import eeimg1 from '../../assets/home/eeimg1new.png';
import eeimg2 from '../../assets/home/eeimg2.png';
import eeimg3 from '../../assets/home/eeimg3new.png';
import eeimg4 from '../../assets/home/eeimg4new.png';
import pinkdotted from '../../assets/home/pinkdotted.png';
import blackdotted from '../../assets/home/blackdotted.png';
import './ExistingMikvah.scss'
import { useTranslation} from 'react-i18next';
const ExistingMikvah = () => {
    const { t, i18n } = useTranslation();
    const ExperienceData = [
        {
            title: "Get mikvahrsvp up and running by tomorrow",
            description1: "The user-friendly platform is designed for quick set up, allowing you to go live without any tech skills. Our dedicated team will provide you with step-by-step guidance and support, ensuring a smooth setup and integration.",
            description2: "Start optimizing your Mikvah operations starting tomorrow!",
            linkbtn: "contact sales",
            imgsrc: eeimg1,
            bluedotcirclesrc: bluedotcircle,
            reddotcirclesrc: bluedotcircle,
            className: "ex-existing-mikvah-img-sec1"
        },
        {
            title: "Smartest way to book appointments",
            description1: "Mikvahrsvp is a centralized platform where guests can quickly make and manage their reservations on a convenient web app. This eliminates the need for manual booking processes, freeing up staff time and resources.",
            linkbtn: "explore reservations",
            imgsrc: eeimg2,
            bluedotcirclesrc: bluedotcircle,
            reddotcirclesrc: reddotcircle,
            className: "ex-existing-mikvah-img-sec2"
        },
        {
            title: "Hands-off management system",
            description1: "With auto scheduling , mikvahrsvp allows you to focus on what truly matters: providing a transformative Mikvah experience for your guests.",
            description2: "Embrace the ease and efficiency of mikvahrsvp's hands-off management system and take your Mikvah to new heights without the hassle.",
            linkbtn: "explore Auto Scheduling",
            imgsrc: eeimg3,
            bluedotcirclesrc: bluedotcircle,
            reddotcirclesrc: pinkdotted,
            className: "ex-existing-mikvah-img-sec3"
        },
        {
            title: "Enhanced guest experience",
            description1: "Patrons enjoy personalized reservations, minimal wait times at the Mikvah, and a discrete process that optimizes their privacy and care.",
            linkbtn: t("CtaStartWeb"),
            imgsrc: eeimg4,
            bluedotcirclesrc: blackdotted,
            reddotcirclesrc: reddotcircle,
            className: "ex-existing-mikvah-img-sec4"
        },
    ]

    const NavData = [
        {
            eventKey: "first",
            imgsrc: smikvah,
            title: t("HomeSolutionsSmall"),
            description: t("HomeSolutionsSmallVisitors")
        },
        {
            eventKey: "second",
            imgsrc: mmikvah,
            title: t("HomeSolutionsMedium"),
            description: t("HomeSolutionsMediumVisitors")
        },
        {
            eventKey: "third",
            imgsrc: lmikvah,
            title: t("HomeSolutionsLarge"),
            description: t("HomeSolutionsLargeVisitors")
        }
    ]

    const sitepoint = [
        "Reservation System",
        "Auto Scheduling",
        "Mikvah Website"
    ]

    return (
        <>
            <Header />
            <Banner 
                className= "existing-mikvah-banner-contents"
                auto= {egmikvahicon}
                meta= ""
                metabtn= "Existing Mikvahs"
                title= "Simple systems, big results"
                description1= "Your Mikvah is an integral staple in your community. Enhance the experience for your guests and staff by streamlining the reservation process. Staff enjoy a relaxed environment and guests enjoy calm, attentive staff!"
                demo={t("CtaStartWeb")}
                contactbtn="contact sales"
                commit= ""
                trial= ""
                bannerhomeimg= {banerchart}
                bannerimg= {phone}
                sitepoint= {sitepoint}
            />
            <Experience
                meta=  {t("HomeBenefitsPretitle")}
                heading= "Get your Mikvah running to the highest level"
                hedingdescription= "Maximize your Mikvah’s potential with easy to use systems that benefit you, your staff, and most importantly, your guests."
                btntext= "GET STARTED!"
                ExperienceData= {ExperienceData}
                className= "existing-mikvah-experience"
            />
            <MikvahPlans 
                span= "Find the right plan"
                title= "What size is your Mikvah?"
                NavData= {NavData}
                className= "existing-plan"
            />
            <Nextlevel 
                className= "existing-mikvah-next-level"
                meta= "Upgrade your Mikvah"
                title= "Start for free today!"
                description1= "Sign up for the Essentials plan, which includes reservations and auto scheduling, and get a free one month’s trial."
                span1= "No commitments or tech skills required!"
                span2= ""
                span3= ""
                btn1= "contact sales"
                btn2= "sign up"
            />
            <Footer />
        </>
    )
}

export default ExistingMikvah