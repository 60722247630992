import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import rightarrow from '../../../assets/home/right-arrow-dark.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Table from 'react-bootstrap/Table';
import './PlansDetail.scss';
import { useTranslation} from 'react-i18next';

const PlansDetail = () => {
    const { t, i18n } = useTranslation();
    const data = [
        { id: 0, label: "10" },
        { id: 1, label: "30" },
        { id: 2, label: "50" },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

  return (
    <section className="new-plan-feature-sec">
        <div className="">
            <div className="plan-feature-wrapper">
                <div className="feature-content">
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <div className="plan-heading">
                                        <button style={{ visibility: 'hidden'}}>
                                            <span>{t("PlanPopularTag")}</span>
                                        </button>
                                        <h2>{t("PlanEss")}</h2>
                                    </div>
                                </th>
                                <th>
                                    <div className="plan-heading">
                                        <button>
                                            <span>{t("PlanPopularTag")}</span>
                                        </button>
                                        <h2>{t("PlanPrem")}</h2>
                                    </div>
                                </th>
                                <th>
                                    <div className="plan-heading">
                                        <button style={{ visibility: 'hidden'}}>
                                            <span>{t("PlanPopularTag")}</span>
                                        </button>
                                        <h2>{t("PlanFull")}</h2>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <div className="plans-description">
                                        <p>{t("PlanEssDesc")}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="plans-description">
                                        <p>{t("PlanPremDesc")}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="plans-description">
                                        <p>{t("PlanFullDesc")}</p>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div className="cost-title">
                                        <h4 className='essential-h4'>{t("PlanEssCost")}</h4>
                                    </div>
                                </td>
                                <td>
                                    <div className="cost-title">
                                        <h4 className='premium-h4'>{t("PlanPremCost")}</h4>
                                    </div>
                                </td>
                                <td>
                                    <div className="cost-title">
                                        <h4 className='service-h4'>{t("PlanFullCost")}</h4>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div className="plans-points">
                                        <ul>
                                            <li>{t("PlanEssBullet1")}</li>
                                            <li>{t("PlanEssBullet2")}</li>
                                            <li>{t("PlanEssBullet3")}</li>
                                            <li>{t("PlanEssBullet4")}</li>
                                            <li>{t("PlanEssBullet6")}</li>
                                            <li>{t("PlanEssBullet5")}</li>
                                        </ul>
                                    </div>
                                    <div className="plans-links essential-plans-links">
                                        <ul>
                                            <li>
                                                <Link to="/Reservation-system">Reservation System</Link>
                                            </li>
                                            <li>
                                                <Link to="/Auto-scheduling">Auto Scheduling</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="plans-points">
                                        <ul>
                                            <li>{t("PlanPremBullet1")}</li>
                                            <li>{t("PlanPremBullet2")}</li>
                                            <li>{t("PlanPremBullet3")}</li>
                                            <li>{t("PlanPremBullet4")}</li>
                                            <li>{t("PlanPremBullet5")}</li>
                                            <li>{t("PlanPremBullet6")}</li>
                                        </ul>
                                    </div>
                                    <div className="plans-links premium-plans-links">
                                        <ul>
                                            <li>
                                                <Link to="/Reservation-system">Reservation System</Link>
                                            </li>
                                            <li>
                                                <Link to="/Auto-scheduling">Auto Scheduling</Link>
                                            </li>
                                            <li>
                                                <Link to="/Interactive-display">Interactive Displays</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="plans-points">
                                        <ul>
                                            <li>{t("PlanFullBullet1")}</li>
                                            <li>{t("PlanFullBullet2")}</li>
                                            <li>{t("PlanFullBullet3")}</li>
                                            <li>{t("PlanFullBullet4")}</li>
                                            <li>{t("PlanFullBullet5")}</li>
                                            <li>{t("PlanFullBullet6")}</li>
                                        </ul>
                                    </div>
                                    <div className="plans-links services-plans-links">
                                        <ul>
                                            <li>
                                                <Link to="/Reservation-system">Reservation System</Link>
                                            </li>
                                            <li>
                                                <Link to="/Auto-scheduling">Auto Scheduling</Link>
                                            </li>
                                            <li>
                                                <Link to="/Interactive-display">Interactive Displays</Link>
                                            </li>
                                            <li>
                                                <Link to="/live-dashboard">Live Dashboard</Link>
                                            </li>
                                            <li>
                                                <Link to="/guidance-system">Guidance System</Link>
                                            </li>
                                            <li>
                                                <Link to="/door-control">Door Control</Link>
                                            </li>
                                            <li>
                                                <Link to="/mikvah-website">Mikvah Website</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <form action="">
                                        <div className="form-group">
                                            <label for="exampleInputPassword1">AVERAGE MONTHLY USERS</label>
                                            <div className="dropdown">
                                                <div className="dropdown-header" onClick={handleDropdownToggle}>
                                                    {selectedOption ? selectedOption.label : "0-50"}
                                                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </div>
                                                {isOpen && (
                                                    <div className=" dropdown-body">
                                                        {data.map((option) => (
                                                            <div
                                                                key={option.id}
                                                                className="dropdown-item"
                                                                onClick={() => handleOptionSelect(option)}
                                                            >
                                                                {option.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </td>
                                <td>
                                    <div className="plan-costs">
                                        <span className='cost-meta' style={{visibility: "hidden"}}>40</span>
                                        <h2 className='cost essential-cost-h2'>$40<span>/Month</span></h2>
                                    </div>
                                </td>
                                <td>
                                    <div className="plan-costs">
                                        <span className='cost-meta'>{t("PlanPremInstall")}</span>
                                        <h2 className='cost premium-cost-h2'>$80<span>/Month</span></h2>
                                    </div>
                                </td>
                                <td>
                                    <div className="plan-costs">
                                        <span className='cost-meta'>{t("PlanFullInstall")}</span>
                                        <h2 className='cost service-cost-h2'>$140<span>/Month</span></h2>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div className="plan-start">
                                        <Link>
                                            <span>{t("CtaStart")}</span>
                                            <img src={rightarrow} alt="rightarrow" />
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    <div className="plan-start">
                                        <Link>
                                            <span>{t("CtaStart")}</span>
                                            <img src={rightarrow} alt="rightarrow" />
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    <div className="plan-start">
                                        <Link>
                                            <span>{t("CtaStart")}</span>
                                            <img src={rightarrow} alt="rightarrow" />
                                        </Link>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </section>
  )
}

export default PlansDetail