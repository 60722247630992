import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import './UserExperience.scss';
import CloseIcon from '@mui/icons-material/Close';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import RemoveIcon from '@mui/icons-material/Remove';
import ordersupply from '../../../assets/home/order-supplies.png';
import checklist from '../../../assets/home/checklist.png';
import helpbtn from '../../../assets/home/help-btn.png';
import settimer from '../../../assets/home/set-timer.png';
import selectlang from '../../../assets/home/select-lang.png';
import { useTranslation } from 'react-i18next';

const UserExperience = () => {

    const {t, i18n} = useTranslation();

    const [volume, setVolume] = useState(1)
    const [muted, setMuted] = useState(false)

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);
    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);
    const handleClose8 = () => setShow8(false);
    const handleShow8 = () => setShow8(true);
    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);

    return (
        <section className="user-experience-sec">
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>THE PATRON'S EXPERIENCE</span>
                        <h2>{t("DispPrepTitle")}</h2>
                        <p>{t("DispPrepBody")}</p>
                    </div>
                    <div className="experience-contents">
                        <div className="experience-items">
                            <div className="experience-img">
                                <Button variant="primary" onClick={handleShow} className="popup-btn btn1">
                                    <span>1</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow1} className="popup-btn btn2">
                                    <span>2</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow2} className="popup-btn btn3">
                                    <span>3</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow3} className="popup-btn btn4">
                                    <span>4</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow4} className="popup-btn btn5">
                                    <span>5</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow5} className="popup-btn btn6">
                                    <span>6</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow6} className="popup-btn btn7">
                                    <span>7</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow7} className="popup-btn btn8">
                                    <span>8</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow8} className="popup-btn btn9">
                                    <span>9</span>
                                </Button>
                                <Button variant="primary" onClick={handleShow9} className="popup-btn btn10">
                                    <span>10</span>
                                </Button>

                                {show && (
                                    <div className="custom-modal">
                                        <div className="custom-modal-content">
                                            <div className="close-icon" onClick={handleClose}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepDashTitle")}</h4>
                                                <p className="description">
                                                {t("DispPrepDashBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show1 && (
                                    <div className="custom-modal">
                                        <div className="custom-modal-content" style={{maxWidth: "365px"}}>
                                            <div className="close-icon" onClick={handleClose1}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepRoomTitle")}</h4>
                                                <p className="description">
                                                {t("DispPrepRoomBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show2 && (
                                    <div className="custom-modal music-custom-modal">
                                        <div className="custom-modal-content music-custom-modal-content">
                                            <div className="close-icon" onClick={handleClose2}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-music-player">
                                                <div className="modal-music-player-header">
                                                    <div className="left-content">
                                                        <p>Music Player</p>
                                                        <span>Select a music station to enjoy while you prepare</span>
                                                    </div>
                                                    <div className="right-content">
                                                        <RemoveIcon />
                                                    </div>
                                                </div>
                                                <div className="modal-music-list">
                                                    <div className="modal-music-list-items">
                                                        <div className="modal-music-list-item">
                                                            <div className="video-item">
                                                                <iframe src="https://www.youtube.com/embed/r-GSGH2RxJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <p className="video-title">Classic Instrumental</p>
                                                        </div>
                                                        <div className="modal-music-list-item">
                                                            <div className="video-item">
                                                                <iframe src="https://www.youtube.com/embed/r-GSGH2RxJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <p className="video-title">Soothing Nature Sound</p>
                                                        </div>
                                                        <div className="modal-music-list-item">
                                                            <div className="video-item">
                                                                <iframe src="https://www.youtube.com/embed/r-GSGH2RxJs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                            </div>
                                                            <p className="video-title">Classic Music</p>
                                                        </div>
                                                        <div className="modal-music-list-item">
                                                            <div className="volume-slider">
                                                                <button onClick={() => setMuted(m => !m)}>
                                                                    {muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
                                                                </button>
                                                                <input
                                                                    type="range"
                                                                    min={0}
                                                                    max={1}
                                                                    step={0.02}
                                                                    value={volume}
                                                                    onChange={event => {
                                                                        setVolume(event.target.valueAsNumber)
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="video-title">Volume <br /> Control</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepMusicTitle")}</h4>
                                                <p className="description">
                                                    {t("DispPrepMusicBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show3 && (
                                    <div className="custom-modal image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose3}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={ordersupply} alt="ordersupply" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepSuppTitle")}</h4>
                                                <p className="description">
                                                {t("DispPrepSuppBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show4 && (
                                    <div className="custom-modal image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose4}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={settimer} alt="settimer" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepTimTitle")}</h4>
                                                <p className="description">
                                                {t("DispPrepTimBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show5 && (
                                    <div className="custom-modal image-modal help-image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose5}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={helpbtn} alt="helpbtn" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepHelpTitle")}</h4>
                                                <p className="description">
                                                    {t("DispPrepHelpBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show6 && (
                                    <div className="custom-modal image-modal help-image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose6}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={checklist} alt="checklist" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepChecTitle")}</h4>
                                                <p className="description">
                                                    {t("DispPrepChecBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show7 && (
                                    <div className="custom-modal">
                                        <div className="custom-modal-content">
                                            <div className="close-icon" onClick={handleClose7}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content">
                                                <h4 className="title">Rabbanim</h4>
                                                <p className="description">
                                                    Patron can easily contact the Rav/ <br />
                                                    Rabbanim on duty.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show8 && (
                                    <div className="custom-modal">
                                        <div className="custom-modal-content">
                                            <div className="close-icon" onClick={handleClose8}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content">
                                                <h4 className="title">{t("DispPrepReadTitle")}</h4>
                                                <p className="description">
                                                    {t("DispPrepReadBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {show9 && (
                                    <div className="custom-modal image-modal help-image-modal date-image-modal">
                                        <div className="custom-modal-content image-modal-content">
                                            <div className="close-icon" onClick={handleClose9}>
                                                <CloseIcon />
                                            </div>
                                            <div className="modal-content modal-order">
                                                <img src={selectlang} alt="selectlang" />
                                            </div>
                                        </div>
                                        <div className="custom-modal-content">
                                            <div className="modal-content">
                                                <h4 className="title">{t("DisPerpLangTitle")}</h4>
                                                <p className="description">
                                                    {t("DispPrepLangBody")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UserExperience