import React, { useEffect, useRef, useState } from 'react';
import Faq from '../../../components/FAQs/Faq';
import MikvahTabs from '../../../components/MikvahTabs/MikvahTabs';
import reddotcircle from '../../../assets/home/red-dot-circle.png';
import pinkdotted from '../../../assets/home/pinkdotted.png';
import blackdotted from '../../../assets/home/blackdotted.png';
import atimg1 from '../../../assets/home/atimg1.png';
import atimg2 from '../../../assets/home/atimg2.png';
import atimg3 from '../../../assets/home/atimg3.png';
import atimg4 from '../../../assets/home/atimg4.png';
import atimg5 from '../../../assets/home/atimg5.png';
import './Benifits.scss';
import { useTranslation} from 'react-i18next';
const Benifits = () => {

    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    const accordionData = [
        {
            title: t("SchedFaq1Title"),
            descriptiontop: t("SchedFaq1Body"),
            descriptionbottom: ""
        },
        {
            title:t("SchedFaq2Title"),
            descriptiontop: t("SchedFaq2Body"),
            descriptionbottom: ""
        },
        {
            title: t("SchedFaq3Title"),
            descriptiontop: t("SchedFaq3Body"),
            descriptionbottom: ""
        },
        {
            title: t("SchedFaq4Title"),
            descriptiontop:t("SchedFaq4Body"),
            descriptionbottom: ""
        },
    ]

    const TabsData = [
        {
            tabtitle: t("SchedBenefitPlanTitle"),
            tabdescription: t("SchedBenefitPlanBody"),
            tabkey: "first"
        },
        {
            tabtitle: t("SchedBenefitResTitle"),
            tabdescription: t("SchedBenefitResBody"),
            tabkey: "second"
        },
        {
            tabtitle: t("SchedBenefitWaitTitle"),
            tabdescription: t("SchedBenefitWaitBody"),
            tabkey: "third"
        },
        {
            tabtitle: t("SchedBenefitNoteTitle"),
            tabdescription: t("SchedBenefitNoteBody"),
            tabkey: "fourth"
        },
        {
            tabtitle:t("SchedBenefitReportsTitle"),
            tabdescription: t("SchedBenefitReportsBody"),
            tabkey: "fifth"
        }
    ]

    const TabsPane = [
        {
            imgsrc: atimg1,
            bluedotcirclesrc: reddotcircle,
            reddotcirclesrc: pinkdotted,
            tabkey: "first",
            className: "tab-auto-img-sec1"
        },
        {
            imgsrc: atimg2,
            bluedotcirclesrc: reddotcircle,
            reddotcirclesrc: blackdotted,
            tabkey: "second",
            className: "tab-auto-img-sec2"
        },
        {
            imgsrc: atimg3,
            bluedotcirclesrc: pinkdotted,
            reddotcirclesrc: reddotcircle,
            tabkey: "third",
            className: "tab-auto-img-sec3"
        },
        {
            imgsrc: atimg4,
            bluedotcirclesrc: pinkdotted,
            reddotcirclesrc: blackdotted,
            tabkey: "fourth",
            className: "tab-auto-img-sec4"
        },
        {
            imgsrc: atimg5,
            bluedotcirclesrc: reddotcircle,
            reddotcirclesrc: pinkdotted,
            tabkey: "fifth",
            className: "tab-auto-img-sec5"
        }
    ]
  return (
    <>
        <section className="benifit-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport benifit-tab-wrapper' : 'benifit-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t("ResPatronPretitle")}</span>
                        <h2>{t("ResPatronTitle")}</h2>
                    </div>
                    <MikvahTabs
                        className= "auto-tab-sec"
                        TabsData= {TabsData}
                        TabsPane= {TabsPane}
                        btnname= {t('CtaStart')}
                    />
                    <div className="booking-options">
                        <h3>{t("SchedBenefitTitle2")}</h3>
                        <div className="d-flex booking-row">
                            <div className="booking-column">
                                <ul>
                                    <li>{t("SchedBenefitBullet1")}</li>
                                    <li>{t("SchedBenefitBullet2")}</li>
                                    <li>{t("SchedBenefitBullet3")}</li>
                                    <li>{t("SchedBenefitBullet4")}</li>
                                    <li>{t("SchedBenefitBullet5")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                    <li>{t("SchedBenefitBullet6")}</li>
                                    <li>{t("SchedBenefitBullet7")}</li>
                                    <li>{t("SchedBenefitBullet8")}</li>
                                    <li>{t("SchedBenefitBullet9")}</li>
                                    <li>{t("SchedBenefitBullet10")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                    <li>{t("SchedBenefitBullet11")}</li>
                                    <li>{t("SchedBenefitBullet12")}</li>
                                    <li>{t("SchedBenefitBullet13")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Faq 
                accordionData= {accordionData}
            />
        </section>
    </>
  )
}

export default Benifits