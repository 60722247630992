import React from 'react';
import './LargeMikvah.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import LMikvahWatch from './LMikvahWatch/LMikvahWatch';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import MikvahrsvpManagement from '../../components/MikvahManagement/MikvahrsvpManagement';
import mikvahicon from '../../assets/home/l-mikvah.png';
import managementbanner from '../../assets/home/management-banner.png';
import newmikvah from '../../assets/home/newmikvah.png';
import existingmikvah from '../../assets/home/existingmikvah.png';
import retrofitmikvah from '../../assets/home/retrofitmikvah.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import lmimg1 from '../../assets/home/lmimg1.png';
import lmimg2 from '../../assets/home/lmimg2.png';
import lmimg3 from '../../assets/home/lmimg3.png';
import lmimg4 from '../../assets/home/newlmimg4.png';
import lmimg5 from '../../assets/home/lmimg5.png';
import spinkdotted from '../../assets/home/spinkdotted.png';
import pinkdotted from '../../assets/home/pinkdotted.png';
import { useTranslation} from 'react-i18next';
const LargeMikvah = () => {
    const { t, i18n } = useTranslation();
  const NavData = [
    {
        eventKey: "first",
        imgsrc: newmikvah,
        title: "New Mikvah",
        description: "BUILDING A MIKVAH"
    },
    {
        eventKey: "second",
        imgsrc: existingmikvah,
        title: "Existing Mikvah",
        description: "UPGRADE MIKVAH, NO RENOVATIONS"
    },
    {
        eventKey: "third",
        imgsrc: retrofitmikvah,
        title: "Retrofit Mikvah",
        description: "UPGRADE MIKVAH, WITH RENOVATIONS"
    }
  ]

  const workData = [
    {
      countNumber: "1",
      counttitle: t("LargWhySub1"),
      btntext: t("CtaSee"),
    },
    {
        countNumber: "2",
        counttitle: t("LargWhySub2"),
        btntext:  t("CtaSee"),
    },
    {
        countNumber: "3",
        counttitle: t("LargWhySub3"),
        btntext: t("CtaSee"),
    },
    {
        countNumber: "4",
        counttitle: t("LargWhySub4"),
        btntext: t("CtaSee"),
    },
    {
        countNumber: "5",
        counttitle: t("LargWhySub5"),
        btntext: t("CtaSee"),
    }
  ]

  const workDataDetail = [
    {
        countNumber: 1,
        meta: t("LargWhySub2"),
        counttitle: t("LargWhySubTitle1"),
        listtitle1: t("LargWhySub1Subtitle1"),
        listitems1: [
            t("LargWhySub1Bullet1"),
            t("LargWhySub1Bullet2"),
            t("LargWhySub1Bullet3"),
            t("LargWhySub1Bullet4"),
            t("LargWhySub1Bullet5"),
        ],
        listtitle2: t("LargWhySub1Subtitle2"),
        listitems2: [
            t("LargWhySub1ABullet1"),
            t("LargWhySub1ABullet2"),
            t("LargWhySub1ABullet3"),
        ],
        manageimg: lmimg1,
        bluedotcircle: spinkdotted,
        className: "mng-lmikvah-img-sec1"
    },
    {
        countNumber: 2,
        meta: t("LargWhySub2"),
        counttitle: t("LargWhySub2Title"),
        listitems1: [
          t("LargWhySub2Bullet1"),
          t("LargWhySub2Bullet2"),
          t("LargWhySub2Bullet3"),
          t("LargWhySub2Bullet4")
        ],
        listtitle2: "",
        listitems2: [
            "",
        ],
        manageimg: lmimg2,
        reddotcircle: reddotcircle,
        bluedotcircle: reddotcircle,
        className: "mng-lmikvah-img-sec2"
    },
    {
        countNumber: 3,
        meta: t("LargWhySub3"),
        counttitle: t("LargWhySub3Title"),
        listtitle1: "",
        listitems1: [
          t("LargWhySub3Bullet1"),
          t("LargWhySub3Bullet2"),
          t("LargWhySub3Bullet3"),
          t("LargWhySub3Bullet4"),
        ],
        listtitle2: "",
        listitems2: [
            "",
        ],
        manageimg: lmimg3,
        reddotcircle: reddotcircle,
        bluedotcircle: pinkdotted,
        className: "mng-lmikvah-img-sec3"
    },
    {
      countNumber: 4,
      meta: t("LargWhySub4"),
      counttitle: t("LargWhySub4Title"),
      listtitle1: "",
      listitems1: [
        t("LargWhySub4Bullet1"),
        t("LargWhySub4Bullet2"),
        t("LargWhySub4Bullet3"),
        t("LargWhySub4Bullet4"),
        t("LargWhySub4Bullet5"),
        t("LargWhySub4Bullet6"),
        t("LargWhySub4Bullet7"),
        t("LargWhySub4Bullet8"),
      ],
      listtitle2: "",
      listitems2: [
          "",
      ],
      manageimg: lmimg4,
      reddotcircle: reddotcircle,
      bluedotcircle: reddotcircle,
      className: "mng-lmikvah-img-sec4"
    },
    {
      countNumber: 5,
      meta: t("LargWhySub5"),
      counttitle: t("LargWhySub5Title"),
      listtitle1: "",
      listitems1: [
        t("LargWhySub5Bullet1"),
        t("LargWhySub5Bullet2"),
        t("LargWhySub5Bullet3"),
        t("LargWhySub5Bullet4"),
        t("LargWhySub5Bullet5"),
      ],
      listtitle2: "",
      listitems2: [
          "",
      ],
      manageimg: lmimg5,
      reddotcircle: reddotcircle,
      bluedotcircle: pinkdotted,
      className: "mng-lmikvah-img-sec5"
    },

  ]

  return (
    <>
        <Header />
        <Banner 
          className= "large-mikvah-banner-contents"
          auto= {mikvahicon}
          meta= {t("LargMikVisitors")}
          metabtn= {t("LargMik")}
          title= {t("LargTitle")}
          description1= {t("LargHeroBody")}
          demo={t("CtaStartWeb")}
          contactbtn="contact sales"
          bannerimg= {managementbanner}
        />
        <MikvahrsvpManagement
          className= "large-mikvah-management"
          meta= {t("LargWhyPretitle")}
          title= {t("LargWhyTitle")}
          description= {t("LargWhyBody")}
          workData= {workData}
          workDataDetail= {workDataDetail}
        />
        <LMikvahWatch />
        <DiffMikvahs 
          className= "mikvah-diff-sec lmikvah-diff-sec"
          meta= {t("SmallCasePretitle")}
          heading= {t("SmallCaseTitle")}
          btn= {t("CtaReadCustomerStories")}
        />
        <MikvahPlans 
          span= {t("LargPlanPretitle")}
          title= {t("LargPlanTitle")}
          NavData= {NavData}
        />
        <Nextlevel 
          className= "lmikvah-next-level"
          title= {t("LargCtaTitle")}
          description= {t("LargCtaBodyTop")}
          description1= {t("LargCtaBody")}
          btn1= {t("CtaSignUp")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default LargeMikvah