import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import smikvah from '../../../assets/home/s-mikvah.png';
import mmikvah from '../../../assets/home/m-mikvah.png';
import lmikvah from '../../../assets/home/l-mikvah.png';
import contactless from '../../../assets/home/contactless.png';
import automated from '../../../assets/home/automated.png';
import reservation from '../../../assets/home/reservation.png';
import advanced from '../../../assets/home/advanced.png';
import diamond from '../../../assets/home/diamond.png';
import enhanced from '../../../assets/home/enhanced.png';
import rightarrow from '../../../assets/home/right-arrow.png';
import tsbg from '../../../assets/home/tsbg.png';
import tsimg from '../../../assets/home/tsimg.png';
import tsbluedotted from '../../../assets/home/tsbluedotted.png';
import tsgreendotted from '../../../assets/home/tsgreendotted.png';
import tmbg from '../../../assets/home/tmbg.png';
import tmimg from '../../../assets/home/newtmimg.png';
import tlbg from '../../../assets/home/tlbg.png';
import tlimg from '../../../assets/home/tlimg.png';
import './Tabs.scss';
import { useTranslation} from 'react-i18next';

const Tabs = () => {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    const NavData = [
        {
            eventKey: "first",
            imgsrc: smikvah,
            title: t("HomeSolutionsSmall"),
            description:t("HomeSolutionsSmallVisitors")
        },
        {
            eventKey: "second",
            imgsrc: mmikvah,
            title:t("HomeSolutionsMedium"),
            description: t("HomeSolutionsMediumVisitors")
        },
        {
            eventKey: "third",
            imgsrc: lmikvah,
            title: t("HomeSolutionsLarge"),
            description: t("HomeSolutionsLargeVisitors")
        }
    ]
  return (
    <>
        <section className="tab-sec tailored">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport tab-wrapper' : 'tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t("HomeSolutionsPretitle")}</span>
                        <h2>{t("HomeSolutionsTitle")}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='tailored-tab-row'>
                            <Col className='top-col'>
                            <Nav variant="pills" className="nav-wrap">
                                {
                                    NavData.map((item, id) => (
                                        <Nav.Item key={id}>
                                            <Nav.Link eventKey={item.eventKey}>
                                                <div className="nav-content">
                                                    <div className="nav-wrap">
                                                        <div className="nav-logo frst-logo">
                                                            <img src={item.imgsrc} alt={item.imgsrc} />
                                                        </div>
                                                        <h4 className="nav-title">
                                                            {item.title}
                                                        </h4>
                                                        <p className="nav-description">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                            </Nav>
                            </Col>
                            <Col className='bottom-col'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="wrap-tab">
                                        <div className="row">
                                            <div className="left-col">
                                                <div className="tab-content">
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={contactless} alt="contactless" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsContactlessTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsContactlessBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={automated} alt="automated" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsPaymentTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsPaymentBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={advanced} alt="advanced" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsSimpleTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsSimpleBody")}
                                                                
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-footer">
                                                        <div className="menu-content">
                                                            <Link to='#'>
                                                                <span>{t("CtaLearn")}</span>
                                                                <img src={rightarrow} alt="rightarrow" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <div className="right-col-wrap">
                                                    <div className="hero-img s-mikvah-imgs">
                                                        <img src={tsimg} alt={tsimg} className='tailoredup-img' />
                                                        {/* <img src={tsbluedotted} alt={tsbluedotted} className='bluedotcircle-img' /> */}
                                                        <img src={tsbluedotted} alt={tsbluedotted} className='greendotcircle-img' />
                                                        <img src={tsbg} alt={tsbg} className='tailoreddown-img' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="wrap-tab">
                                        <div className="row">
                                            <div className="left-col">
                                                <div className="tab-content">
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={reservation} alt="reservation" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                                {t("HomeSolutionsEffortlessTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsEffortlessBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={enhanced} alt="enhanced" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsEnhancedTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsEnhancedBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={advanced} alt="advanced" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsSimpleTitleMedium")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsSimpleBodyMedium")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-footer">
                                                        <div className="menu-content">
                                                            <Link to='#'>
                                                                <span>{t("CtaLearn")}</span>
                                                                <img src={rightarrow} alt="rightarrow" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <div className="right-col-wrap">
                                                    <div className="hero-img m-mikvah-imgs">
                                                        <img src={tmimg} alt={tmimg} className='tailoredup-img' />
                                                        <img src={tsbluedotted} alt={tsbluedotted} className='bluedotcircle-img' />
                                                        <img src={tmbg} alt={tmbg} className='tailoreddown-img' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="wrap-tab">
                                        <div className="row">
                                            <div className="left-col">
                                                <div className="tab-content">
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={advanced} alt="advanced" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsAutomationTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsAutomationBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={diamond} alt="diamond" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsUltimateTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsUltimateBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={automated} alt="automated" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("HomeSolutionsEfficiencyTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("HomeSolutionsEfficiencyBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-footer">
                                                        <div className="menu-content">
                                                            <Link to='#'>
                                                                <span>{t("CtaLearn")}s</span>
                                                                <img src={rightarrow} alt="rightarrow" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <div className="right-col-wrap">
                                                    <div className="hero-img l-mikvah-imgs">
                                                        <img src={tlimg} alt={tlimg} className='tailoredup-img' />
                                                        {/* <img src={tsbluedotted} alt={tsbluedotted} className='bluedotcircle-img' /> */}
                                                        <img src={tlbg} alt={tlbg} className='tailoreddown-img' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                        </Tab.Container>
                </div>
            </div>
        </section>
    </>
  )
}

export default Tabs