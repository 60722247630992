import React from 'react';
import './WatchAction.scss';
import Faq from '../../../components/FAQs/Faq';
import WatchInAction from '../../../components/WatchInAction/WatchInAction';
import { useTranslation} from 'react-i18next';
const WatchAction = () => {
    const { t, i18n } = useTranslation();
    const accordionData = [
        {
            title: t("ResFaq1Title"),
            descriptiontop: t("ResFaq1Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq2Title"),
            descriptiontop: t("ResFaq2Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq3Title"),
            descriptiontop: t("ResFaq3Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq4Title"),
            descriptiontop:t("ResFaq4Body"),
            descriptionbottom: ""
        },
        {
            title:t("ResFaq5Title"),
            descriptiontop: t("ResFaq5Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq6Title"),
            descriptiontop: t("ResFaq6Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq7Title"),
            descriptiontop: t("ResFaq7Body"),
            descriptionbottom: ""
        },
        {
            title: t("ResFaq8Title"),
            descriptiontop: t("ResFaq8Body"),
            descriptionbottom: ""
        },
    ]
  return (
    <section className='watch-section'>
        <div className="container">
            <WatchInAction
                watchtitle= {t('ResVideoTitle')}
            />
        </div>
        <Faq 
            accordionData= {accordionData}
        />
    </section>
  )
}

export default WatchAction