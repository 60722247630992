import React from 'react';
import { Link } from 'react-router-dom';
import './Reservation.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import ReserveBanner from '../ReservationSystem/ReservationBanner/ReservationBanner';
import UserTabs from './UserVerticalTabs/UserTabs';
import Workmanagement from '../../components/WorkManagement/Management';
import ManagendStaff from './ManagendStaff/ManagendStaff';
import WatchAction from './WatchAction/WatchAction';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import user from '../../assets/home/user.png';
import date from '../../assets/home/date.png';
import check from '../../assets/home/check.png';
import done from '../../assets/home/done.png';
import { useTranslation} from 'react-i18next';
const Reservation = () => {
  const { t, i18n } = useTranslation();
  const workData = [
    {
        imgsrc: user,
        countNumber: "1.",
        counttitle: t("ResBookSub1Title"),
        countdescription: t("ResBookSub1Body")
    },
    {
        imgsrc: date,
        countNumber: "2.",
        counttitle: t("ResBookSub2Title"),
        countdescription:  t("ResBookSub2Body")
    },
    {
        imgsrc: check,
        countNumber: "3.",
        counttitle: t("ResBookSub3Title"),
        countdescription: ''
    },
    {
        imgsrc: done,
        countNumber: "4.",
        counttitle: t("ResBookSub4Title"),
        countdescription: t("ResBookSub4Body")
    }
  ]

  const metalinks = () => (
    <>Or, explore more features such as the <Link to="/Interactive-display">{t("IntDisp")}</Link>,<Link to="/live-dashboard">live dashboard</Link>, <Link to="/mikvah-website">mikvah website</Link>, and more.</>
  )
  return (
    <>
        <Header />
        <ReserveBanner />
        <Workmanagement 
          workData= {workData}
          meta= {t("ResBookPretitle")}
          title= {t("ResBookTitle")}
        />
        <UserTabs />
        <ManagendStaff />
        <WatchAction />
        <Nextlevel 
          className= "reservation-next-level"
          meta= {t("ResCtaPretitle")}
          title= {t("ResCtaTitle")}
          description= {t("ResCtaBody")}
          description1= {t("ResCtaBodyNew")}
          span2= ""
          span3= ""
          btn1= {t("CtaSignUp")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default Reservation