import React, {useState, useEffect} from 'react';
import './Rcv.scss';

const Rcv = () => {
    const [counter, setCounter] = useState(0);
    const [counter1, setCounter1] = useState(0);
    const [counter2, setCounter2] = useState(0);
  
    useEffect(() => {
      if (counter < 50) {
        const interval = setInterval(() => {
          setCounter((prevCounter) => prevCounter + 1);
        }, 10); 
  
        return () => {
          clearInterval(interval);
        };
      }
    }, [counter]);
  
    useEffect(() => {
      if (counter1 < 27) {
        const interval = setInterval(() => {
          setCounter1((prevCounter) => prevCounter + 1);
        }, 10); 
  
        return () => {
          clearInterval(interval);
        };
      }
    }, [counter1]);
  
    useEffect(() => {
      if (counter2 < 6200) {
        const interval = setInterval(() => {
          setCounter2((prevCounter) => prevCounter + 100);
        }, 10); 
  
        return () => {
          clearInterval(interval);
        };
      }
    }, [counter2]);

    return (
        <>
            <section className="rcv-sec">
                <div className="container">
                    <div className="rcv-wrapper">
                        <div className="rcv-items">
                            <div className="rcv-item">
                                <div className="rcv-item-wrap">
                                    <div className="rcv-setails">
                                        <h2 className="counter">{counter}k+</h2>
                                        <h4 className="counter-title">Reservations</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="rcv-item">
                                <div className="rcv-item-wrap">
                                    <div className="rcv-setails">
                                        <h2 className="counter">{counter1}</h2>
                                        <h4 className="counter-title">Communities</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="rcv-item">
                                <div className="rcv-item-wrap">
                                    <div className="rcv-setails">
                                        <h2 className="counter">{counter2}+</h2>
                                        <h4 className="counter-title">Visitors</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rcv