import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import checkicon from '../../../assets/home/dark-blue-check.png';
import helpicon from '../../../assets/home/help-icon.png';
import rightarrow from '../../../assets/home/right-arrow-dark.png';
import './PlansFeatures.scss';
import { useTranslation } from 'react-i18next';

const PlansFeatures = () => {
    const { t, i18n } = useTranslation();

    const features = [
        { title: t("PlanRestTitle"), isInfo: false, isChecked: true, isSubheading: false, isEssentials: false, isPremium: false, isService: false},
        { title: t("PlanResSub1"), tootltip:"Sign in quickly with saved login information", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanResSub2"), tootltip:"Sign in quickly with Saved Credit Card Info", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanResSub3"), tootltip:"Sign in quickly with Secure Payments", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanResSub4"), tootltip:"Sign in quickly with Private patron notes", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanResSub5"), tootltip:"Sign in quickly with SMS Reminders", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanResSub6"), tootltip:"Sign in quickly with Direct Deposit Payments", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},

        { title: "AUTO SCHEDULING", isInfo: false, isChecked: true, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanSchedSub1"), tootltip:"Sign in quickly with Hebrew Calendar Integration", isInfo: false, isEssentials: true, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanSchedSub2"), tootltip:"Sign in quickly with Daily reservations list", isInfo: true, isEssentials: true, isPremium: true, isService: true, check: checkicon,},

        { title: t("PlanCheckTitle"), isInfo: false, isChecked: true, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanCheckSub1"), tootltip:"Sign in quickly with Manual Check in", isInfo: true, isEssentials: true, isPremium: true, isService: false, check: checkicon,},
        { title: t("PlanCheckSub2"), tootltip:"Sign in quickly with Automatic Check in", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanCheckSub3"), tootltip:"Sign in quickly with Manual Room Assignment", isInfo: true, isEssentials: true, isPremium: true, isService: false, check: checkicon,},
        { title: t("PlanCheckSub4"), tootltip:"Sign in quickly with Automatic Room Assignment", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanCheckSub5"), tootltip:"Sign in quickly with Automatic Walk-in Check in + Assignment", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},

        { title: t("PlanDispTitle"), isInfo: false, isChecked: true, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanDispRoom"), isInfo: false, isChecked: true, isSubheading: true, isEssentials: false, isPremium: false, isService: false},
        { title: t("PlanDispRoomSub1"), tootltip:"Sign in quickly with Timer", isInfo: true, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRoomSub2"), tootltip:"Sign in quickly with Supply Requests", isInfo: true, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRoomSub3"), tootltip:"Sign in quickly with Music Control", isInfo: true, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRoomSub4"), tootltip:"Sign in quickly with Interactive Checklist", isInfo: true, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRoomSub5"), tootltip:"Sign in quickly with Ready for Tevilah Button", isInfo: true, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRoomSub6"), tootltip:"Sign in quickly with Language Settings", isInfo: false, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRec"), isInfo: false, isChecked: true, isSubheading: true, isEssentials: false, isPremium: false, isService: false},
        { title: t("PlanDispRecSub1"), tootltip:"Sign in quickly with Shows when room is ready to toivel", isInfo: false, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub2"), tootltip:"Sign in quickly with Shows any requested supplies", isInfo: false, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub3"), tootltip:"Sign in quickly with Shows patron's reservation notes", isInfo: false, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub4"), tootltip:"Sign in quickly with Manual room status", isInfo: false, isEssentials: false, isPremium: true, isService: false, check: checkicon,},
        { title: t("PlanDispRecSub5"), tootltip:"Sign in quickly with Automatic room tracking status", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub6"), tootltip:"Sign in quickly with Track rooms needing cleaning", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub7"), tootltip:"Sign in quickly with Shows prep time spent by each user", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub8"), tootltip:"Sign in quickly with Shows which room is next on line to toivel", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub9"), tootltip:"Sign in quickly with Overview of night schedule", isInfo: false, isEssentials: false, isPremium: true, isService: true, check: checkicon,},
        { title: t("PlanDispRecSub10"), tootltip:"Sign in quickly with Online Admins Page", isInfo: true, isEssentials: false, isPremium: true, isService: false, check: checkicon,},

        { title: t("PlanDashTitle"), isInfo: false, isChecked: true, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanDashSub1"), tootltip:"Sign in quickly with Interactive live map", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashSub2"), tootltip:"Sign in quickly with Check in patron with or without reservation", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashSub3"), tootltip:"Sign in quickly with Make future reservations", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashSub4"), tootltip:"Sign in quickly with Access any room's screens", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashSub5"), tootltip:"Sign in quickly with Snapshot of upcoming reservations", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashSub6"), tootltip:"Sign in quickly with Manage payments, reservations, and patron accounts", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashMik"), isChecked: true, isInfo: false, isSubheading: true, isEssentials: false, isPremium: false, isService: false},
        { title: t("PlanDashMikSub1"), tootltip:"Sign in quickly with Shows current room in Tevilah", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashMikSub2"), tootltip:"Sign in quickly with Shows next room and supplies", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDashClean"), isChecked: true, isInfo: false, isSubheading: true, isEssentials: false, isPremium: false, isService: false},
        { title: t("PlanDashCleanSub1"), tootltip:"Sign in quickly with Shows rooms to be cleaned and which are next", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        
        { title: t("PlanGuidTitle"), isChecked: true, isInfo: false, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanGuidSub1"), tootltip:"Sign in quickly with LED Room Signs", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},

        { title: t("PlanDoorTitle"), isChecked: true, isInfo: false, isEssentials: false, isPremium: false, isService: false,},
        { title: t("PlanDoorSub1"), tootltip:"Sign in quickly with Patrons can enter facility with keypad",isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDoorSub2"), tootltip:"Sign in quickly with LED Room SignsAssigned room will automatically unlock for user",isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDoorSub3"), tootltip:"Sign in quickly with Keycard access for attendant",isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDoorSub4"), tootltip:"Sign in quickly with Data reports", isInfo: true, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
        { title: t("PlanDoorSub5"), tootltip:"Sign in quickly with Set preferences for Shabbos", isInfo: false, isEssentials: false, isPremium: false, isService: true, check: checkicon,},
       
    ];

    return (
        <section className="plan-feature-sec">
            <div className="">
                <div className="plan-feature-wrapper">
                    <div className="feature-header">
                        <h2>{t("PlanFeaturesTitle")}</h2>
                    </div>
                    <div className="feature-content">
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>{t("PlanEss")}</th>
                                    <th>{t("PlanPrem")}</th>
                                    <th>{t("PlanFull")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {features.map((feature, index) => (
                                    <tr key={index}>
                                        <td></td>
                                        <td>
                                            <div className="td-content">
                                                {!feature.isChecked ? (
                                                    <span>{feature.title}</span>
                                                ) : (
                                                    <p className={feature.isSubheading ? "sub-heading" : ""}>{feature.title}</p>
                                                )}
                                                {feature.isInfo ? (
                                                    <div class="tooltip"><img src={helpicon} alt="helpicon" />
                                                        <span class="tooltiptext">{feature.tootltip}</span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="check-content">
                                                {feature.isEssentials ? (
                                                    <img src={checkicon} alt={checkicon} />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="check-content">
                                                {feature.isPremium ? (
                                                    <img src={checkicon} alt={checkicon} />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="check-content">
                                                {feature.isService ? (
                                                    <img src={checkicon} alt={checkicon} />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div className="plan-start">
                                            <Link>
                                                <span>{t("CtaStart")}</span>
                                                <img src={rightarrow} alt="rightarrow" />
                                            </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="plan-start">
                                            <Link>
                                                <span>{t("CtaStart")}</span>
                                                <img src={rightarrow} alt="rightarrow" />
                                            </Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="plan-start">
                                            <Link>
                                                <span>{t("CtaStart")}</span>
                                                <img src={rightarrow} alt="rightarrow" />
                                            </Link>
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PlansFeatures;
