import React from 'react';
import './Sheduling.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import Benifits from './MikvahBenefits/Benifits';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import Workmanagement from '../../components/WorkManagement/Management'
import auto from '../../assets/home/auto-white.png';
import autobanner from '../../assets/home/auto-shedulebg.png';
import user from '../../assets/home/user.png';
import upload from '../../assets/home/upload.png';
import loop from '../../assets/home/loop.png';
import happy from '../../assets/home/happy.png';
import { useTranslation} from 'react-i18next';
const Sheduling = () => {
  const { t, i18n } = useTranslation();
  const workData = [
    {
        imgsrc: user,
        countNumber: "1.",
        counttitle: t("SchedManagSub1Title"),
        countdescription: t("SchedManagSub1Body"),
        countspan: "Reservation System",
        countlink: "/Reservation-system"
    },
    {
        imgsrc: upload,
        countNumber: "2.",
        counttitle: t("SchedManagSub2Title"),
        countdescription: ""
    },
    {
        imgsrc: loop,
        countNumber: "3.",
        counttitle: t("SchedManagSub3Title"),
        countdescription: ""
    },
    {
        imgsrc: happy,
        countNumber: "4.",
        counttitle: t("SchedManagSub4Title"),
        countdescription: ""
    },
  
  ]
  const staticText = ' • Get a';
  return (
    <>
      <Header />
      <Banner 
        className= "Scheduling-banner"
        auto= {auto}
        meta= {t("EssentialsPretitle")}
        metabtn= {t("AutoSched")}
        title= {t("SchedTitle")}
        description1= {t("SchedHeroBody")}
        demo="try for free!"
        commit= {t("CtaSubNocom") + staticText}
        getlink= "free trial"
        trial= "  today!"
        bannerimg= {autobanner}
      />
      <Workmanagement 
          workData= {workData}
          meta= {t("SchedManagPretitle")}
          title= {t("SchedManagTitle")}
      />
      <Benifits />
      <Nextlevel 
        className= "sheduling-next-level"
        meta= "UPGRADE YOUR MIKVAH"
        title= "Start for free today!"
        description1= "Sign up for the Basic plan, which includes reservation and auto scheduling, and get a free one month’s trial. No commitments or tech skills required. Your Mikvah can be up and running in under one hour!"
        span1= "Or, explore more features such as the kiosk system, live dashboard, mikvah website, and more."
        span2= ""
        span3= ""
        btn1= {t("CtaSignUp")}
        btn2= "Contact Sales"
      />
      <Footer />
    </>
  )
}

export default Sheduling