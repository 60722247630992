import React from 'react';
import './SmallMikvah.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import Experience from '../../components/MikvahExperience/Experience';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import MikvahAbout from '../../components/MikvahAbout/MikvahAbout';
import sgmikvahicon from '../../assets/home/s-mikvah.png';
import smallbanner from '../../assets/home/small-mikvah-banner.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import smabout from '../../assets/home/smabout.png';
import mmdoted from '../../assets/home/mmdoted.png';
import newmikvah from '../../assets/home/newmikvah.png';
import existingmikvah from '../../assets/home/existingmikvah.png';
import retrofitmikvah from '../../assets/home/retrofitmikvah.png';
import smeximg1 from '../../assets/home/smeximg1new.png';
import smeximg2 from '../../assets/home/newsmeximg2.png';
import smeximg3 from '../../assets/home/smeximg3new.png';
import smeximg4 from '../../assets/home/smeximg4.png';
import { useTranslation} from 'react-i18next';
const SmallMikvah = () => {

  const { t, i18n } = useTranslation();

  const ExperienceData = [
    {
        title: t("SmallWhyOnlineTitle"),
        description1: t("SmallWhyOnlineBody"),
        imgsrc: smeximg1,
        reddotcirclesrc: bluedotcircle,
        className: "ex-smikvah-img-sec1"
    },
    {
        title: t("SmallWhySchedTitle"),
        description1: t("SmallWhySchedBody"),
        imgsrc: smeximg2,
        reddotcirclesrc: bluedotcircle,
        className: "ex-smikvah-img-sec2"
    },
    {
        title: t("SmallWhyPayTitle"),
        description1: t("SmallWhyPayBody"),
        imgsrc: smeximg3,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-smikvah-img-sec3"
    },
    {
        title: t("SmallWhyGuestTitle"),
        description1: t("SmallWhyGuestBody"),
        imgsrc: smeximg4,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-smikvah-img-sec4"
    },
  ]

  const NavData = [
    {
        eventKey: "first",
        imgsrc: newmikvah,
        title: t("PlanNewTitle"),
        description: t("PlanNewSubtitle")
    },
    {
        eventKey: "second",
        imgsrc: existingmikvah,
        title: t("PlanExistTitle"),
        description: t("PlanExistSubtitle")
    },
    {
        eventKey: "third",
        imgsrc: retrofitmikvah,
        title: t("PlanRetroTitle"),
        description: t("PlanRetroSubtitle")
    }
  ]
  return (
    <>
        <Header />
        <Banner 
          className= "small-mikvah-banner-contents"
          auto= {sgmikvahicon}
          meta= {t("SmallMikVisitors")}
          metabtn= {t("SmallMik")}
          title= {t("SmallTitle")}
          description1= {t("SmallHeroBody")}
          description2= ""
          demo={t("CtaStart")}
          commit= ""
          trial= ""
          bannerimg= {smallbanner}
        />
        <MikvahAbout
          title= {t("SmallSolutionTitle")}
          description1= {t("SmallSolutionBody")}
          description2= ""
          imgsrc= {smabout}
          bluedotcirclesrc= {mmdoted}
        />
        <Experience
          meta= {t("SmallWhyPretitle")}
          heading= {t("SmallWhyTitle")}
          btntext= "GET STARTED!"
          ExperienceData= {ExperienceData}
          className= "mikvah-experience s-mikvah-experience"
        />
        <MikvahPlans 
          span= {t("SmallPlanPretitle")}
          title= {t("SmallPlanTitle")}
          NavData= {NavData}
        />
        <DiffMikvahs 
          className= "mikvah-diff-sec"
          meta= {t("SmallCasePretitle")}
          heading= {t("SmallCaseTitle")}
          btn= {t("CtaReadCustomerStories")}
        />
        <Nextlevel 
          className= "smikvah-next-level"
          title= {t("SmallCtaTitle")}
          description= {t("SmallCtaBodyTop")}
          description1= {t("SmallCtaBody")}
          btn1= {t("CtaSignUp")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default SmallMikvah