import React from 'react';
import WatchInAction from '../../../components/WatchInAction/WatchInAction';
import './NMikvahWatch.scss';

const NMikvahWatch = () => {
  return (
    <section className='Nmwatch-section'>
        <div className="container">
            <WatchInAction
              className= "NargeWatchIn"
              watchtitle= "Watch the full system in action"
            />
        </div>
    </section>
  )
}

export default NMikvahWatch