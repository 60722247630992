import React from 'react';
import { Link } from 'react-router-dom';
import './Nextlevel.scss';
import { useTranslation} from 'react-i18next';
const Nextlevel = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <section className={`nextlevel-section ${props.className}`}>
            <div className="container">
                <div className="nextlevel-content">
                    <div className="text-content">
                        <span className='meta-span'>{props.meta}</span>
                        <h2>{props.title}</h2>
                        <p>{props.description}</p>
                        <p>{props.description1}</p>
                        <p>
                            <span>{props.span1}</span>
                            <span>{props.span2}</span>
                            <span>{props.span3}</span>
                        </p>
                        <div className="nextlevel-buttons">
                            <a href="/" className="btn sign-up">{props.btn1}</a>
                            <a href="/" className="btn contact-sale">{props.btn2}</a>
                        </div>
                        <div className="smikvah-footer">
                            <p>{t("CtaSubNocom")} • Get a <Link to='/'>free trial</Link> today on basic plan!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Nextlevel