import React from 'react';
import './InteractiveDisplay.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Banner from '../../components/Banner/Banner';
import UserExperience from './UserExperience/UserExperience';
import ExperienceTablet from './ExperienceTablet/ExperienceTablet';
import InteractiveStory from './InteractiveStory/InteractiveStory';
import Faq from '../../components/FAQs/Faq';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import Interactivetablet from '../../assets/home/newInteractivebanner.png';
import interactiveicon from '../../assets/home/interactive-icon.png';
import { useTranslation} from 'react-i18next';
const InteractiveDisplay = () => {
    const { t, i18n } = useTranslation();
  const accordionData = [
    {
      title: t("DispFaq1Title"),
      descriptiontop: t("DispFaq1Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("DispFaq2Title"),
      descriptiontop: t("DispFaq2Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("DispFaq3Title"),
      descriptiontop: t("DispFaq3Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("DispFaq4Title"),
      descriptiontop: t("DispFaq4Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("DispFaq5Title"),
      descriptiontop: t("DispFaq5Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
]
  return (
    <>
        <Header />
        <Banner
          className= "Interactive-banner"
          auto= {interactiveicon}
          meta= "PREMIUM PLAN"
          metabtn= "Interactive Display"
          title= {t("DispTitle")}
          description1= {t("DispHeroBody")}
          description2= ""
          demo="get a quote"
          commit= ""
          trial= ""
          bannerimg= {Interactivetablet}
      />
        <UserExperience />
        <ExperienceTablet />
        <InteractiveStory />
        <Faq 
          className= "Interactive-faq"
          accordionData= {accordionData}
        />
        <Nextlevel 
          className= "interactive-next-level"
          meta= {t("DispCtaPretitle")}
          title= {t("DispCtaTitle")}
          description1= {t("DispCtaBody")}
          span1= ""
          span2= ""
          span3= ""
          btn1= {t('CtaPlan')}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default InteractiveDisplay