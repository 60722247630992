import React from 'react';
import './Faq.scss';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation} from 'react-i18next';
const Faq = (props) => {
    const { t, i18n } = useTranslation();
  return (
    <section className={`faq-sec ${props.className}`}>
        <div className="container">
            <div className="faq-content">
                <div className="left-sec">
                    <div className="faq-details">
                        <h2>{t("FaqTitle")}</h2>
                        <div className="faq-describe">
                            <p>
                              {t("FaqBody")}
                            </p>
                            <p>
                                {/* Don't see your question? <br />
                                <span className='heighlight-span'>Get in touch</span> with us!  */}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="right-sec">
                    <div className="accordion-wrap">
                        <Accordion>
                            {
                                props.accordionData.map((item, id) => (
                                    <Accordion.Item eventKey={id} key={id}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>{item.descriptiontop}</p>
                                            <p>{item.descriptionbottom}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Faq