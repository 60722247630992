import React, { useEffect, useRef, useState }  from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import premiumsite from '../../../assets/home/premium-site.png';
import basicsite from '../../../assets/home/basic-site.png'
import advanced from '../../../assets/home/advanced.png';
import rightarrow from '../../../assets/home/right-arrow.png';
import donate from '../../../assets/home/donate.png';
import hoursicon from '../../../assets/home/hoursicon.png';
import contacticon from '../../../assets/home/contacticon.png';
import labguageicon from '../../../assets/home/labguageicon.png';
import bwimg from '../../../assets/home/newbwimg.png';
import blackdotted from '../../../assets/home/blackdotted.png';
import pwimg from '../../../assets/home/newpwimg.png';
import './MikvahTab.scss';
import { useTranslation } from 'react-i18next';

const MikvahTab = () => {
    const {t, i18n} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);


    return (
        <section className="mikvah-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport mkvah-tab-wrapper' : 'mkvah-tab-wrapper'}>
                    <div className="heading-sec">
                        <h2>{t("WebOptionsTitle")}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='option-tab-row'>
                            <Col className='top-col'>
                            <Nav variant="pills" className="nav-wrap">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <div className="nav-content">
                                            <div className="nav-wrap">
                                                <div className="nav-logo">
                                                    <img src={basicsite} alt="basicsite" />
                                                </div>
                                                <h4 className="nav-title">
                                                {t("WebBasicToggle")}
                                                </h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <div className="nav-content">
                                            <div className="nav-wrap">
                                                <div className="nav-logo">
                                                    <img src={premiumsite} alt="premiumsite" />
                                                </div>
                                                <h4 className="nav-title">
                                                {t("WebPremiumToggle")}
                                                </h4>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            </Col>
                            <Col className='bottom-col'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="wrap-tab">
                                        <div className="row bottom-col-row">
                                            <div className="left-col">
                                                <div className="tab-content">
                                                    <div className="content-items head-content-items">
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebBasicFeeTitle")}:
                                                            </h4>
                                                            <h3 className='content-payment'>{t("WebBasicFee")}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={advanced} alt="advanced" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsBasicHomeTitle")}
                                                            </h4>
                                                            <ul>
                                                                <li>{t("WebOptionsBasicHomeBullets1")}</li>
                                                                <li>{t("WebOptionsBasicHomeBullets2")}</li>
                                                                <li>{t("WebOptionsBasicHomeBullets3")}</li>
                                                                <li>{t("WebOptionsBasicHomeBullets4")}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={donate} alt="donate" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsBasicDonateTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsBasicDonateBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={hoursicon} alt="hoursicon" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsBasicHoursTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsBasicHoursBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={contacticon} alt="contacticon" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsBasicContactTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsBasicContactBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={labguageicon} alt="labguageicon" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsBasicLangTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsBasicLangBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-footer">
                                                        <div className="menu-content">
                                                            <Link to='#'>
                                                                <span>GET STARTED NOW</span>
                                                                <img src={rightarrow} alt="rightarrow" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <div className="right-col-wrap">
                                                    <div className="hero-img bw-images">
                                                        <img src={bwimg} alt="bwimg" className='bwimg' />
                                                        <img src={blackdotted} alt="bluedotted" className='bluedotted' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="wrap-tab">
                                    <div className="row bottom-col-row">
                                            <div className="left-col">
                                                <div className="tab-content">
                                                    <div className="content-items head-content-items">
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebPremiumFeeTitle")}
                                                            </h4>
                                                            <h3 className='content-payment'>{t("WebPremiumFee")}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                        </div>
                                                        <div className="content-text" style={{paddingLeft: "35px"}}>
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsPremiumOfferTitle")} +
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={donate} alt="donate" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsPremiumThemeTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsPremiumThemeBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="content-items">
                                                        <div className="content-icon">
                                                            <img src={hoursicon} alt="hoursicon" />
                                                        </div>
                                                        <div className="content-text">
                                                            <h4 className="content-heading">
                                                            {t("WebOptionsPremiumKeilTitle")}
                                                            </h4>
                                                            <p className="content-description">
                                                            {t("WebOptionsPremiumKeilBody")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-footer">
                                                        <div className="menu-content">
                                                            <Link to='#'>
                                                                <span>GET STARTED NOW</span>
                                                                <img src={rightarrow} alt="rightarrow" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-col">
                                                <div className="right-col-wrap">
                                                    <div className="hero-img pw-images">
                                                        <img src={pwimg} alt="pwimg" className='pwimg' />
                                                        <img src={blackdotted} alt="blackdotted" className='bluedotted' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}

export default MikvahTab