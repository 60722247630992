import React from 'react';
import { Link } from 'react-router-dom';
import reservation from '../../assets/home/reservation.png';
import Auto from '../../assets/home/Auto.png';
import Interactive from '../../assets/home/Interactive.png';
import mwebsite from '../../assets/home/m-website.png';
import live from '../../assets/home/live.png';
import guidance from '../../assets/home/guidance.png';
import door from '../../assets/home/door.png';
import rightarrowdark from '../../assets/home/right-arrow-dark.png';
import './Features.scss';
import { useTranslation} from 'react-i18next';
const Features = (props) => {
    
  const { t, i18n } = useTranslation();
    return (
        <div className={`features-content ${props.className}`}>
            <div className="feature-row feature-row-top d-flex">
                <div className="feature-column more-half d-flex flex-wrap">
                    <div className="inner-feature-column width_60">
                        <div>
                            <div className="feature-icon">
                                <img src={reservation} alt="reservation" />
                                <h4>{t("PlanResTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("PlanResBody")}.</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                    <div className="inner-feature-column width_35">
                        <div>
                            <div className="feature-icon">
                                <img src={Auto} alt="Auto" />
                                <h4 className="color-rose-pink">{t("PlanSchedTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("PlanSchedBody")}</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                    <div className="inner-feature-column full-width">
                        <div className="d-flex align-items-center">
                            <div className="feature-icon">
                                <img src={Interactive} alt="Interactive" />
                                <h4 className="color-deep-blue">{t("PlanDispTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("PlanDispBody")}</p>
                                <div className="feature-button">
                                    <Link to="#">
                                        <span>LEARN MORE</span>
                                        <img src={rightarrowdark} alt="rightarrowdark" />
                                    </Link>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="feature-column mid-half">
                    <div className="inner-feature-column">
                        <div>
                            <div className="feature-icon">
                                <img src={mwebsite} alt="mwebsite" />
                                <h4 className="color-violet">{t("PlanWebTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("PlanWebBody")}</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feature-row col-gap">
                <div className="feature-column d-flex">
                    <div className="inner-feature-column">
                        <div>
                            <div className="feature-icon">
                                <img src={live} alt="live" />
                                <h4 className="color-rose-pink">{t("HomeProdDashTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("HomeProdDashBody")}</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                    <div className="inner-feature-column">
                        <div>
                            <div className="feature-icon">
                                <img src={guidance} alt="guidance" />
                                <h4 className="color-violet">{t("HomeProdGuidTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("HomeProdGuidBody")}</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                    <div className="inner-feature-column">
                        <div>
                            <div className="feature-icon">
                                <img src={door} alt="door" />
                                <h4>{t("HomeProdDoorTitle")}</h4>
                            </div>
                            <div className="feature-text">
                                <p>{t("HomeProdDoorBody")}</p>
                            </div>
                        </div>
                        <div className="feature-button">
                            <Link to="#">
                                <span>LEARN MORE</span>
                                <img src={rightarrowdark} alt="rightarrowdark" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features