import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation } from "react-router-dom";
import './Header.scss';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import reservation from '../../assets/home/reservation.png';
import auto from '../../assets/home/Auto.png';
import interactive from '../../assets/home/Interactive.png';
import guidance from '../../assets/home/guidance.png';
import door from '../../assets/home/door.png';
import msite from '../../assets/home/m-website.png';
import live from '../../assets/home/live.png';
import logo from '../../assets/home/header-logo.png';
import rightarrow from '../../assets/home/right-arrow.png';
import lgmikvahicon from '../../assets/home/lg-mikvah-icon.png';
import mgmikvahicon from '../../assets/home/mg-mikvah-icon.png';
import sgmikvahicon from '../../assets/home/sg-mikvah-icon.png';
import egmikvahicon from '../../assets/home/eg-mikvah-icon.png';
import rgmikvahicon from '../../assets/home/rg-mikvah-icon.png';
import ngmikvahicon from '../../assets/home/ng-mikvah-icon.png';
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
// ...
const locales = {
  en: { title: 'English' },
  lv: { title: 'Latviski' },
};

const Header = () => {

    const [mactice, setMactive] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const gotomnav = (() => {
        setMactive(!mactice);
    })

    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const goToPage = (page) => {
        navigate(page, { replace: true });
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 992);
        };
    
        handleResize();
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    const { t, i18n } = useTranslation();
  return (
    <>
    
        <header className={scroll ? "header sticky-header" : "header"}>
            <Container>
                <Navbar expand="lg" className={mactice ? "active-header" : ""}>
                    <Navbar.Brand className="brand-logo" onClick={ () => { goToPage('/')}}>
                        <img src={logo} alt="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" onClick={() => gotomnav()} />
                    <Navbar.Collapse id="navbarScroll" className='nav-collapse'>
                        <Nav
                            className="my-2 my-lg-0"
                            navbarScroll
                        >
                            {isMobile ? (
                                <>
                                    <NavDropdown title={t("MenuPlans")} id="navbarScrollingDropdown" className='plan-menus'>
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Essentials</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/Reservation-system' className={splitLocation[1] === "Reservation-system" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={reservation} alt="reservation" width={26} height={26} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>{t("ResSystem")}</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Auto-scheduling' className={splitLocation[1] === "Auto-scheduling" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={auto} alt="auto" width={27} height={27} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Auto Sheduling</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Premium</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/Interactive-display' className={splitLocation[1] === "Interactive-display" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={interactive} alt="interactive" width={31} height={30} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>{t("IntDisp")}</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Full Service</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/live-dashboard' className={splitLocation[1] === "live-dashboard" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={live} alt="live" width={37} height={26} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Live Dashboard</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/guidance-system' className={splitLocation[1] === "guidance-system" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={guidance} alt="guidance" width={33} height={33} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Guidance System</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/door-control' className={splitLocation[1] === "door-control" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={door} alt="door" width={23} height={29} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Door Control</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Optional Features</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/mikvah-website' className={splitLocation[1] === "mikvah-website" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={msite} alt="msite" width={29} height={25} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Mikvah Website</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                <Link to='/mikvah-plans'>
                                                    <span>Compare Plans</span>
                                                    <img src={rightarrow} alt="rightarrow" />
                                                </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                    <NavDropdown title={t("MenuSolutions")} id="navbarScrollingDropdown" className='solution-menus'>
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                    <div className="menu-item new-menu-item">
                                                        <h4 className="menu-heading">By Size</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/small-mikvah' className={splitLocation[1] === "small-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={sgmikvahicon} alt="sgmikvahicon" width={30} height={15} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>{t("HomeSolutionsSmall")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/medium-mikvah' className={splitLocation[1] === "medium-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={mgmikvahicon} alt="mgmikvahicon" width={45} height={22} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>{t("HomeSolutionsMedium")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/large-mikvah' className={splitLocation[1] === "large-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={lgmikvahicon} alt="lgmikvahicon" width={66} height={25} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span> {t("HomeSolutionsLarge")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="menu-item new-menu-item">
                                                        <h4 className="menu-heading">BY NEED</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/new-mikvah' className={splitLocation[1] === "new-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={ngmikvahicon} alt="ngmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>New Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/existing-mikvah' className={splitLocation[1] === "existing-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={egmikvahicon} alt="egmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>Existing Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/retrofitting-mikvah' className={splitLocation[1] === "retrofitting-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={rgmikvahicon} alt="rgmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>Retrofitting Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                    <Link to='/mikvah-plans'>
                                                        <span>Compare Plans</span>
                                                        <img src={rightarrow} alt="rightarrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                    <NavDropdown title={t("MenuAbout")} id="navbarScrollingDropdown" className='about-menus'>
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                    <div className="menu-item new-menu-item">
                                                        <h4 className="menu-heading">LEARN ABOUT MIKVAHRSVP</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/about' className={splitLocation[1] === "about" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-title">
                                                                        <span>About Us</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/customer-stories' className={splitLocation[1] === "customer-stories" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-title">
                                                                        <span>Customer Stories</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                    <Link to='/mikvah-plans'>
                                                        <span>Compare Plans</span>
                                                        <img src={rightarrow} alt="rightarrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                </>
                                
                            ) : (
                                <>
                                    <NavDropdown title={t("MenuPlans")} id="navbarScrollingDropdown">
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Essentials</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/Reservation-system' className={splitLocation[1] === "Reservation-system" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={reservation} alt="reservation" width={26} height={26} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>{t("ResSystem")}</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/Auto-scheduling' className={splitLocation[1] === "Auto-scheduling" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={auto} alt="auto" width={27} height={27} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Auto Sheduling</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Premium</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/Interactive-display' className={splitLocation[1] === "Interactive-display" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={interactive} alt="interactive" width={31} height={30} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>{t("IntDisp")}</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Full Service</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/live-dashboard' className={splitLocation[1] === "live-dashboard" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={live} alt="live" width={37} height={26} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Live Dashboard</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/guidance-system' className={splitLocation[1] === "guidance-system" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={guidance} alt="guidance" width={33} height={33} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Guidance System</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    <Link to='/door-control' className={splitLocation[1] === "door-control" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={door} alt="door" width={23} height={29} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Door Control</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                <div className="menu-item">
                                                    <h4 className="menu-heading">Optional Features</h4>
                                                    <div className="menu-sub-content">
                                                    <Link to='/mikvah-website' className={splitLocation[1] === "mikvah-website" ? "active" : ""}>
                                                        <div className="menu-sub-item">
                                                        <div className="menu-sub-img">
                                                            <img src={msite} alt="msite" width={29} height={25} />
                                                        </div>
                                                        <div className="menu-sub-title">
                                                            <span>Mikvah Website</span>
                                                        </div>
                                                        </div>
                                                    </Link>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                <Link to='/mikvah-plans'>
                                                    <span>Compare Plans</span>
                                                    <img src={rightarrow} alt="rightarrow" />
                                                </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                    <NavDropdown title={t("MenuSolutions")} id="navbarScrollingDropdown" className='solution-menus'>
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                    <div className="menu-item new-menu-item">
                                                        <h4 className="menu-heading">By Size</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/small-mikvah' className={splitLocation[1] === "small-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={sgmikvahicon} alt="sgmikvahicon" width={30} height={15} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>{t("HomeSolutionsSmall")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/medium-mikvah' className={splitLocation[1] === "medium-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={mgmikvahicon} alt="mgmikvahicon" width={45} height={22} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>{t("HomeSolutionsMedium")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/large-mikvah' className={splitLocation[1] === "large-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={lgmikvahicon} alt="lgmikvahicon" width={66} height={25} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span> {t("HomeSolutionsLarge")}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="menu-item new-menu-item">
                                                        <h4 className="menu-heading">BY NEED</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/new-mikvah' className={splitLocation[1] === "new-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={ngmikvahicon} alt="ngmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>New Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/existing-mikvah' className={splitLocation[1] === "existing-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={egmikvahicon} alt="egmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>Existing Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/retrofitting-mikvah' className={splitLocation[1] === "retrofitting-mikvah" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-img">
                                                                        <img src={rgmikvahicon} alt="rgmikvahicon" width={30} height={30} />
                                                                    </div>
                                                                    <div className="menu-sub-title">
                                                                        <span>Retrofitting Mikvahs</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                    <Link to='/mikvah-plans'>
                                                        <span>Compare Plans</span>
                                                        <img src={rightarrow} alt="rightarrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                    <NavDropdown title={t("MenuAbout")} id="navbarScrollingDropdown">
                                        <div className="container">
                                            <div className="menu-row">
                                                <div className="menu-wrap">
                                                    <div className="menu-item new-menu-item last-new-menu-item">
                                                        <h4 className="menu-heading">LEARN ABOUT MIKVAHRSVP</h4>
                                                        <div className="menu-sub-content">
                                                            <Link to='/about' className={splitLocation[1] === "about" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-title">
                                                                        <span>About Us</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Link to='/customer-stories' className={splitLocation[1] === "customer-stories" ? "active" : ""}>
                                                                <div className="menu-sub-item">
                                                                    <div className="menu-sub-title">
                                                                        <span>Customer Stories</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="menu-footer">
                                                <div className="menu-content">
                                                    <Link to='/mikvah-plans'>
                                                        <span>Compare Plans</span>
                                                        <img src={rightarrow} alt="rightarrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </NavDropdown>
                                </>
                            )}
                            <Link to='#' className="nav-link">{t("MenuPricing")}</Link>
                        </Nav>
                        <Form className="d-flex">
                            <Button className='sign-btn'>
                                <Link to="#">
                                    <span>{t("CtaSignUp")}</span>
                                </Link>
                            </Button>
                        </Form>
                        <div>
    
    </div>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </header>
    </>
  )
}

export default Header