import React, { useEffect, useRef, useState } from 'react';
import MikvahTabs from '../../../components/MikvahTabs/MikvahTabs';
import bluedotcircle from '../../../assets/home/blue-dot-circle.png';
import pinkdotted from '../../../assets/home/pinkdotted.png';
import dbluedotted from '../../../assets/home/dbluedotted.png';
import rtimg1 from '../../../assets/home/rtimg1.png';
import rtimg2 from '../../../assets/home/rtimgnew2.png';
import rtimg3 from '../../../assets/home/rtimgnew3.png';
import rtimg4 from '../../../assets/home/rtimgnew4.png';
import './UserTabs.scss';
import { useTranslation} from 'react-i18next';
const UserTabs = () => {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    const TabsData = [
        {
            tabtitle: t('ResPatronProcessTitle'),
            tabdescription: t('ResPatronProcessBody'),
            tabkey: "first"
        },
        {
            tabtitle: t('ResPatronPrivTitle'),
            tabdescription: t('ResPatronPrivBody'),
            tabkey: "second"
        },
        {
            tabtitle: t('ResPatronAppTitle'),
            tabdescription: t('ResPatronAppBody'),
            tabkey: "third"
        },
        {
            tabtitle: t('ResPatronStripeTitle'),
            tabdescription: t('ResPatronStripeBody'),
            tabkey: "fourth"
        },
    ]

    const TabsPane = [
        {
            imgsrc: rtimg1,
            bluedotcirclesrc: bluedotcircle,
            reddotcirclesrc: pinkdotted,
            tabkey: "first",
            className: "tab-rsystem-img-sec1"
        },
        {
            imgsrc: rtimg2,
            bluedotcirclesrc: pinkdotted,
            reddotcirclesrc: pinkdotted,
            tabkey: "second",
            className: "tab-rsystem-img-sec2"
        },
        {
            imgsrc: rtimg3,
            bluedotcirclesrc: dbluedotted,
            reddotcirclesrc: bluedotcircle,
            tabkey: "third",
            className: "tab-rsystem-img-sec3"
        },
        {
            imgsrc: rtimg4,
            reddotcirclesrc: pinkdotted,
            tabkey: "fourth",
            className: "tab-rsystem-img-sec4"
        }
    ]
  return (
    <>
        <section className="the-exp-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport user-tab-wrapper' : 'user-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t('ResPatronPretitle')}</span>
                        <h2>{t('ResPatronTitle')}</h2>
                    </div>
                    <MikvahTabs
                        className= "usertab-sec reserve-usertab-sec"
                        TabsData= {TabsData}
                        TabsPane= {TabsPane}
                        btnname= {t("CtaStart")}
                    />
                    <div className="booking-options">
                        <h3>{t("ResPatronTitle2")}</h3>
                        <div className="d-flex booking-row">
                            <div className="booking-column">
                                <ul>
                                    <li>{t("ResPatronBullet1")}</li>
                                    <li>{t("ResPatronBullet2")}</li>
                                    <li>{t("ResPatronBullet3")}</li>
                                    <li>{t("ResPatronBullet4")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                <li>{t("ResPatronBullet5")}</li>
                                    <li>{t("ResPatronBullet6")}</li>
                                    <li>{t("ResPatronBullet7")}</li>
                                    <li>{t("ResPatronBullet8")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <ul>
                                    <li>{t("ResPatronBullet9")}</li>
                                    <li>{t("ResPatronBullet10")}</li>
                                    <li>{t("ResPatronBullet11")}</li>
                                    <li>{t("ResPatronBullet12")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default UserTabs