import React from 'react';
import leader1 from '../../assets/home/leader1.png';
import leader2 from '../../assets/home/leader2.png';
import leader3 from '../../assets/home/leader3.png';
import './LeaderShip.scss'

const LeaderShip = () => {
    const leadersData = [
        {
            leaderimg: leader1,
            leadername: "Rivkah Bloom",
            designation: "Project Manager"
        },
        {
            leaderimg: leader2,
            leadername: "Zalman Stock",
            designation: "Project Manager"
        },
        {
            leaderimg: leader3,
            leadername: "Mendel Nemanov",
            designation: "Lead Developer"
        },
        {
            leaderimg: leader1,
            leadername: "Rivkah Bloom",
            designation: "Project Manager"
        },
        {
            leaderimg: leader2,
            leadername: "Zalman Stock",
            designation: "Project Manager"
        },
        {
            leaderimg: leader3,
            leadername: "Mendel Nemanov",
            designation: "Lead Developer"
        },
    ]
    return (
        <>
            <section className="leadership-sec">
                <div className="container">
                    <div className="leadership-wrapper">
                        <div className="leadership-contents">
                            <div className="top-heading">
                                <h1>Our Leadership</h1>
                            </div>
                            <div className="leadership-items">
                                {
                                    leadersData.map((item, id) => (
                                        <div className="leadership-item" key={id}>
                                            <div className="item-content">
                                                <div className="leader-img">
                                                    <img src={item.leaderimg} alt={item.leaderimg} />
                                                </div>
                                                <div className="leader-detail">
                                                    <h4 className="leader-name">{item.leadername}</h4>
                                                    <span className="leader-designation"><i>{item.designation}</i></span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LeaderShip