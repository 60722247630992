import React, {useState} from 'react';
import './Footer.scss';
import bannerbg from '../../assets/home/homer-bannerbg.png';
import footerlogo from '../../assets/home/header-logo.png';
import logomikvah from '../../assets/home/tabs-logo-mikhvah.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import contactless from '../../assets/home/contactless.png';
import rightarrow from '../../assets/home/right-arrow.png';
import { useTranslation} from 'react-i18next';
const Footer = () => {
    const { t, i18n } = useTranslation();

    const data = [{id: 0, label: t("FRoleSub1")}, {id: 1, label: t("FRoleSub2")}, {id: 2, label: t("FRoleSub3")}, {id: 3, label: t("FRoleSub4")}];
    const data1 = [{id: 0, label: "1"}, {id: 1, label: "2"}, {id: 2, label: "3"}, {id: 3, label: "4"}, {id: 4, label: "5"}];
    const data2 = [{id: 0, label: "1"}, {id: 1, label: "2"}, {id: 2, label: "3"}];
    const data3 = [
        {id: 0, label: "1"}, {id: 1, label: "2"}, {id: 2, label: "3"}, {id: 3, label: "4"}, {id: 4, label: "5"},
        {id: 5, label: "6"}, {id: 6, label: "7"}, {id: 7, label: "8"}, {id: 8, label: "9"}, {id: 9, label: "10"},
        {id: 10, label: "11"}, {id: 11, label: "12"}, {id: 12, label: "13"}, {id: 13, label: "14"}, {id: 44, label: "15"}
    ];
    const data4 = [{id: 0, label: t("FStageSub1")}, {id: 1, label: t("FStageSub2")}, {id: 2, label: t("FStageSub3")}, {id: 0, label: t("FStageSub4")}];
    
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [isOpen1, setIsOpen1] = useState(false);
    const [selectedOption2, setSelectedOption2] = useState(null);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedOption3, setSelectedOption3] = useState(null);
    const [isOpen3, setIsOpen3] = useState(false);
    const [selectedOption4, setSelectedOption4] = useState(null);
    const [isOpen4, setIsOpen4] = useState(false);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleDropdownToggle1 = () => {
        setIsOpen1(!isOpen1);
    };
    const handleDropdownToggle2 = () => {
        setIsOpen2(!isOpen2);
    };
    const handleDropdownToggle3 = () => {
        setIsOpen3(!isOpen3);
    };
    const handleDropdownToggle4 = () => {
        setIsOpen4(!isOpen4);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    const handleOptionSelect1 = (option) => {
        setSelectedOption1(option);
        setIsOpen1(false);
    };
    const handleOptionSelect2 = (option) => {
        setSelectedOption2(option);
        setIsOpen2(false);
    };
    const handleOptionSelect3 = (option) => {
        setSelectedOption3(option);
        setIsOpen3(false);
    };
    const handleOptionSelect4 = (option) => {
        setSelectedOption4(option);
        setIsOpen4(false);
    };
  return (
    <>
        <div className="footer-contents">
            <div className="top-footer" style={{backgroundImage: `url(${bannerbg})`}}>
                <div className="container">
                    <div className="footer-wrap">
                        <div className="footer-header">
                            <div className="footer-logo">
                                <img src={footerlogo} alt="footerlogo" />
                            </div>
                        </div>
                        <div className="footer-content">
                            <div className="left-sec">
                                <div className="left-content">
                                    <div className="top-content">
                                        <h1 className='title'>{t("ContactTitle")}</h1>
                                        <div className="help-sec">
                                            <h3>{t("ContactSubtitle")}</h3>
                                            <div className="help-description">
                                                <ul>
                                                    <li>{t("ContactBullet1")}</li>
                                                    <li>{t("ContactBullet2")}</li>
                                                    <li>{t("ContactBullet3")}</li>
                                                    <li>{t("ContactBullet4")}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-content">
                                        <div className="bottom-content-logo">
                                            <img src={logomikvah} alt="logomikvah" />
                                        </div>
                                        <div className="bottom-description">
                                            <p>
                                                “As a mikvah lady for many years, mikvahrsvp is a 
                                                refreshing solution for our clients and staff. It’s easy for the 
                                                women to set up customized appointments, and easy on 
                                                our end to fulfill the requests.”
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-sec">
                                <div className="form-wrap">
                                    <div className="form-content">
                                        <form action="/" method="post">
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">{t("FName")}</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder={t("FNameFiller")} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FEmail")}</label>
                                                <input type="email" className="form-control" id="exampleInputPassword1" placeholder={t("FEmailFiller")} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FPhone")}</label>
                                                <input type="tel" className="form-control" id="exampleInputPassword1" placeholder="(555) 55-5555" />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FLocation")}</label>
                                                <input type="email" className="form-control" id="exampleInputPassword1" placeholder={t("FLocationFiller ")} />
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FRole")}</label>
                                                <div className="dropdown">
                                                    <div className="dropdown-header" onClick={handleDropdownToggle}>
                                                        {selectedOption ? selectedOption.label : "Mikvah Administrator"}
                                                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </div>
                                                    {isOpen && (
                                                        <div className=" dropdown-body">
                                                            {data.map((option) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="dropdown-item"
                                                                    onClick={() => handleOptionSelect(option)}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group h-form-group">
                                                <h3 className="form-heading">
                                                    {t("Fsubtitle1")}
                                                </h3>
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FPrep")}</label>
                                                <div className="dropdown">
                                                    <div className="dropdown-header" onClick={handleDropdownToggle1}>
                                                        {selectedOption1 ? selectedOption1.label : "1-5"}
                                                        {isOpen1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </div>
                                                    {isOpen1 && (
                                                        <div className=" dropdown-body">
                                                            {data1.map((option) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="dropdown-item"
                                                                    onClick={() => handleOptionSelect1(option)}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FBoros")}</label>
                                                <div className="dropdown">
                                                    <div className="dropdown-header" onClick={handleDropdownToggle2}>
                                                        {selectedOption2 ? selectedOption2.label : "1-3"}
                                                        {isOpen2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </div>
                                                    {isOpen2 && (
                                                        <div className=" dropdown-body">
                                                            {data2.map((option) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="dropdown-item"
                                                                    onClick={() => handleOptionSelect2(option)}
                                                                >
                                                                {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FGuests")}</label>
                                                <div className="dropdown">
                                                    <div className="dropdown-header" onClick={handleDropdownToggle3}>
                                                        {selectedOption3 ? selectedOption3.label : "1-15"}
                                                        {isOpen3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </div>
                                                    {isOpen3 && (
                                                        <div className=" dropdown-body">
                                                            {data3.map((option) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="dropdown-item"
                                                                    onClick={() => handleOptionSelect3(option)}
                                                                >
                                                                {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label for="exampleInputPassword1">{t("FStage")}</label>
                                                <div className="dropdown">
                                                    <div className="dropdown-header" onClick={handleDropdownToggle4}>
                                                        {selectedOption4 ? selectedOption4.label : "Building new Mikvah"}
                                                        {isOpen4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </div>
                                                    {isOpen4 && (
                                                        <div className=" dropdown-body">
                                                            {data4.map((option) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="dropdown-item"
                                                                    onClick={() => handleOptionSelect4(option)}
                                                                >
                                                                {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group h-form-group">
                                                <h3 className="form-heading">
                                                    {t("FComments")}
                                                </h3>
                                            </div>
                                            <div className="form-group">
                                                <textarea name="comment" form="usrform" placeholder={t("FCommentsFiller")}>
                                                </textarea>
                                            </div>
                                            <div className="form-btn">
                                                <button type='submit'>
                                                    <span>{t("CtaSubmit")}</span>
                                                    <img src={rightarrow} alt="rightarrow" />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="bottom-footer-wrap">
                        <div className="bottom-footer-content">
                            <div className="left-content">
                                <div className="content-items">
                                    <div className="item-img">
                                        <img src={contactless} alt="contactless" />
                                    </div>
                                    <div className="item-detail">
                                        <h3>{t("ContactGeneralTitle")}</h3>
                                        <p>
                                            {t("ContactGeneralBody")}
                                        </p>
                                        <p>Call: <span>xxx-xxx-xxxx</span></p>
                                        <p>Email: <span>info@mikvahrsvp.com</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="right-content">
                                <div className="content-items">
                                    <div className="item-img">
                                        <img src={contactless} alt="contactless" />
                                    </div>
                                    <div className="item-detail">
                                        <h3>{t("ContactSupportTitle")}</h3>
                                        <p>
                                            {t("ContactSupportBody")}
                                        </p>
                                        <p>Call: <span>xxx-xxx-xxxx</span></p>
                                        <p>Email: <span>info@mikvahrsvp.com</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Footer