import React from 'react';
import { Link } from 'react-router-dom';
import './WatchInAction.scss';
import { useTranslation} from 'react-i18next';
const WatchInAction = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <section className={`watch-in ${props.className}`}>
            <div className="watch-wrapper">
                <div className="heading-sec">
                    <h2>{props.watchtitle}</h2>
                </div>
                <div className="video-sec">
                    <div className="video-img">
                        <iframe className="elementor-video-iframe trx_addons_resize" allowfullscreen="" title="vimeo Video Player" src="https://player.vimeo.com/video/622528098?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=1&amp;title=1&amp;portrait=1&amp;byline=1&amp;h=f50b42a701#t=">
                        </iframe>
                    </div>
                </div>
                <div className="buttons-sec">
                    <Link className="sale-btn" to="/reservation">
                        <span>{t("CtaDemo")}</span>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default WatchInAction