import React from 'react';
import Slider from "react-slick";
import slide2 from '../../assets/home/slide1.png';
import slide3 from '../../assets/home/slide2.png';
import slide4 from '../../assets/home/slide3.png';
import slide1 from '../../assets/home/bimg4.png';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './MikvahSlider.scss';

const MikvahSlider = () => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          }
        ]
      };
    return (
        <>
            <section className="slider-sec">
                <div className="slider-wrapper">
                    <Slider {...settings} className='slider-content'>
                        <img src={slide1} alt="slide1" />
                        <img src={slide2} alt="slide2" />
                        <img src={slide3} alt="slide3" />
                        <img src={slide4} alt="slide4" />
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default MikvahSlider