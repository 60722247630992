import React from 'react';
import './Trusted.scss';

const Trusted = (props) => {
    return (
        <>
            <section className="trusted-sec">
                <div className="container">
                    <div className='manage-tab-wrapper'>
                        <div className="heading-sec">
                            <h2>Trusted by <span>174,635</span> reservations worldwide</h2>
                        </div>
                        <div className="experience-contents">
                            <div className="experience-items">
                                {
                                    props.workData.map((item, id) => (
                                        <div className="experience-item" key={id}>
                                            <div className="experience-wrap">
                                                <div className="experience-details">
                                                    <div className="count-img">
                                                        <img src={item.imgsrc} alt="" />
                                                    </div>
                                                    <div className="count-title">
                                                        <p>{item.counttitle}</p>
                                                        <span>{item.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Trusted