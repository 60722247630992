import React from 'react';
import './Mikvahmsg.scss';

const Mikvahmsg = () => {
    return (
        <>
            <section className="mikvah-msg">
                <div className="container">
                    <div className="mikvah-msg-wrapper">
                        <div className="mikvah-msg-content">
                            <div className="left-content">
                                <div className="author-content">
                                    <div className="heading">
                                        <p>
                                            "A Mikveh should be 
                                            beautiful both spiritually 
                                            and aesthetically”
                                        </p>
                                        <span>— THE LUBAVITCHER REBBE</span>
                                    </div>
                                </div>
                            </div>
                            <div className="right-content">
                                <div className="text-content">
                                    <h2 className='title'>How should a Mikvah run?</h2>
                                    <div className="content-description">
                                        <p>
                                            Throughout history, the Mitzvah of Mikvah has held a significant and often 
                                            difficult role in the lives of Jewish women. Whether it was concealed mikvaos 
                                            in the midst of the Spanish Inquisition or frozen lakes in communist Russia, 
                                            this mitzvah was faithfully observed through immense dedication and an 
                                            unwavering commitment to Peru U'Revu.
                                        </p>
                                        <p>
                                            In the present day, the practice of Mikvah has evolved to adapt to our changed 
                                            circumstances, culture, and unique challenges. Our modern culture and the 
                                            obstacles we face may differ, but our unwavering dedication remains constant. 
                                            Our commitment now lies in preserving the flame of Judaism, allowing it to 
                                            thrive and spread.
                                        </p>
                                        <p>
                                            In larger communities, privacy has become a significant concern for mikvaos, 
                                            while in smaller communities, administrators often grapple with reservations 
                                            and complex logistics. Acknowledging the Lubavitcher Rebbe's strong 
                                            advocacy for the enhancement of Mikvaos, we have embraced his sentiment 
                                            wholeheartedly.
                                        </p>
                                        <p>
                                            <strong>
                                                Our mission is clear: make the mitzvah of Mikvah 
                                                accessible, luxurious, and joyful for every Jewish woman.
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mikvahmsg