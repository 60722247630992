import React from 'react';
import WatchInAction from '../../../components/WatchInAction/WatchInAction';
import './RMikvahWatch.scss';

const RMikvahWatch = () => {
  return (
    <section className='rmwatch-section'>
        <div className="container">
            <WatchInAction
              className= "NargeWatchIn"
              watchtitle= "Watch it in action"
            />
        </div>
    </section>
  )
}

export default RMikvahWatch