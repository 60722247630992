import React, {useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import Tabs from './TailoredTabs/Tabs';
import Features from './Features/Features';
import Banner from '../../components/Banner/Banner';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Experience from '../../components/MikvahExperience/Experience';
import Footer from '../../components/NewFooter/Footer';
import phone from '../../assets/home/home-phone.png';
import banerchart from '../../assets/home/home-banner-bg.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import heximg1 from '../../assets/home/heximgnew1.png';
import heximg2 from '../../assets/home/heximg2.png';
import heximg3 from '../../assets/home/heximg3.png';
import pinkdotted from '../../assets/home/pinkdotted.png';
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
const Home = () => {

  const [text, setText] = useState("Meaningful");

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      setText(prevText => {
        if (prevText === t('HomeTitleAlt1') ) {
          return t('HomeTitleAlt2');
        } else if (prevText === t('HomeTitleAlt2')) {
          return t('HomeTitleAlt3');
        } else if (prevText === t('HomeTitleAlt3')) {
          return t('HomeTitleAlt4');
        } else {
          return t('HomeTitleAlt1');
        }
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const ExperienceData = [
    {
        title: t("HomeBenefitsExperienceTitle"),
        description1: t("HomeBenefitsExperienceBody"),
        
        imgsrc: heximg1,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: pinkdotted,
        className: "ex-home-img-sec1"
    },
    {
        title: t("HomeBenefitsHightechTitle"),
        description1: t("HomeBenefitsHightechBody"),

        imgsrc: heximg2,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-home-img-sec2"
    },
    {
        title: t("HomeBenefitsEverythingTitle"),
        description1: t("HomeBenefitsEverythingBody"),
        imgsrc: heximg3,
        bluedotcirclesrc: bluedotcircle,
        reddotcirclesrc: reddotcircle,
        className: "ex-home-img-sec3"
    },
  ]
  const headerHeading = () => (
    <>{t('HomeTitle')} <i>{text}</i></>
  )
  const staticText = ' • Get a'
  return (
    <>
        <Header />
        <Banner 
          className= "home-banner-contents"
          title= {headerHeading()}
          description1= {t('HomeHeroBody')} 
          demo={t("CtaSignUp")}
          contactbtn={t("CtaSales")}
          commit= {t("CtaSubNocom") + staticText}
          getlink= "free trial"
          trial= "  today!"
          bannerhomeimg= {banerchart}
          bannerimg= {phone}
        />
        <Tabs />
        <Experience
          meta= {t("HomeBenefitsPretitle")}
          heading= {t("HomeBenefitsTitle")}
          btntext= {t("CtaContact")}
          ExperienceData= {ExperienceData}
          className= "home-experience"
        />
        <DiffMikvahs
          className="home-dif-sec"
        />
        <Features />
        <Nextlevel 
          className= "home-next-level"
          title= {t("HomeCtaTitle")}
          description= {t("HomeCtaBodyTop")}
          description1= {t("HomeCtaBody")}
          btn1= {t("CtaSignUp")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default Home
