import React, { useEffect, useRef, useState } from 'react';
import './ExperienceTablet.scss';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import MikvahTabs from '../../../components/MikvahTabs/MikvahTabs';
import reddotcircle from '../../../assets/home/red-dot-circle.png';
import pinkdotted from '../../../assets/home/pinkdotted.png';
import blackdotted from '../../../assets/home/blackdotted.png';
import whitedotted from '../../../assets/home/whitedotted.png';
import ipimg1 from '../../../assets/home/ipm01.png';
import ipimg2 from '../../../assets/home/ipimg2.png';
import ipimg3 from '../../../assets/home/ipimg3.png';
import ipimg4 from '../../../assets/home/ipimg4.png';
import ifimg1 from '../../../assets/home/ifimg1.png';
import { useTranslation} from 'react-i18next';
const ExperienceTablet = () => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const { t, i18n } = useTranslation();
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;

      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    const TabsData = [
        {
            tabtitle: t("DispStaffCheckTitle"),
            tabdescription: t("DispStaffCheckBody"),
            tabkey: "first"
        },
        {
            tabtitle: t("DispStaffResTitle"),
            tabdescription: t("DispStaffResBody"),
            tabkey: "second"
        },
        {
            tabtitle: t("DispStaffNotesTitle"),
            tabdescription: t("DispStaffNotesBody"),
            tabkey: "third"
        },
        {
            tabtitle: t("DispStaffRoomTitle"),
            tabdescription: t("DispStaffRoomBody"),
            tabkey: "fourth"
        }
    ]

    const TabsData2 = [
        {
            tabtitle: "Live Dashboard at Reception",
            tabdescription: "The dashboard has everything on hand to ensure the Mikvah is running smoothly.",
            tabkey: "first"
        }
    ]

    const TabsPane2 = [
        {
            imgsrc: ifimg1,
            bluedotcirclesrc: pinkdotted,
            reddotcirclesrc: whitedotted,
            tabkey: "first",
            className: "tab-iservice-img-sec1"
        }
    ]

    const TabsPane = [
        {
            imgsrc: ipimg1,
            bluedotcirclesrc: reddotcircle,
            reddotcirclesrc: pinkdotted,
            tabkey: "first",
            className: "tab-ipremium-img-sec1"
        },
        {
            imgsrc: ipimg2,
            bluedotcirclesrc: pinkdotted,
            reddotcirclesrc: whitedotted,
            tabkey: "second",
            className: "tab-ipremium-img-sec2"
        },
        {
            imgsrc: ipimg3,
            bluedotcirclesrc: blackdotted,
            reddotcirclesrc: reddotcircle,
            tabkey: "third",
            className: "tab-ipremium-img-sec3"
        },
        {
            imgsrc: ipimg4,
            bluedotcirclesrc: whitedotted,
            reddotcirclesrc: pinkdotted,
            tabkey: "fourth",
            className: "tab-ipremium-img-sec4"
        }
    ]
    return (
        <section className="experience-tab-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport experience-tab-wrapper' : 'experience-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t("DispStaffPretitle")}</span>
                        <h2>{t("DispStaffTitle")}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='new-tab-row'>
                            <Col className='top-col'>
                                <Nav variant="pills" className="nav-wrap">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <div className="nav-content">
                                                <div className="nav-wrap">
                                                    <h4 className="nav-title">
                                                        {t('PremiumPretitle')}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <div className="nav-content">
                                                <div className="nav-wrap">
                                                    <h4 className="nav-title">
                                                        {t("FullToggle")}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col className='bottom-col'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <MikvahTabs
                                            className= "Interactive-tab-sec"
                                            TabsData= {TabsData}
                                            TabsPane= {TabsPane}
                                            btnname= "SIGN UP NOW"
                                            btnlink= "#"
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <MikvahTabs
                                            className= "Interactive-tab-sec Interactive-tab-sec-second"
                                            TabsData= {TabsData2}
                                            TabsPane= {TabsPane2}
                                            btnname= "EXPLORE DASHBOARD"
                                            btnlink= "/live-dashboard"
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}

export default ExperienceTablet