import React from 'react';
import './Dashboard.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import Reception from './DashboardReception/Reception';
import OptionalDashboard from './OptionalDashboard/OptionalDashboard';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Faq from '../../components/FAQs/Faq';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import Banner from '../../components/Banner/Banner';
import MikvahAbout from '../../components/MikvahAbout/MikvahAbout';
import livedashboard from '../../assets/home/live-dashboard.png';
import dashboardBanner from '../../assets/home/live-dashboard-banner.png';
import dabout from '../../assets/home/newdaboutbg.png';
import daboutbg from '../../assets/home/daboutbg.png';
import daboutdotted from '../../assets/home/daboutdotted.png';
import redshape from '../../assets/home/redshape.png';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const {t, i18n} = useTranslation();
  const accordionData = [
    {
      title: t("FullFaq1Title"),
      descriptiontop: t("FullFaq1Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("FullFaq2Title"),
        descriptiontop: "Absolutely! If you have two or more women using your Mikvah on any given night, this system will greatly benefit you. If you have maybe five women a month, come back to us when you (G-d willing!) grow your attendance.",
        descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("FullFaq3Title"),
      descriptiontop: t("FullFaq3Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    },
    {
      title: t("FullFaq4Title"),
      descriptiontop: t("FullFaq4Body"),
      descriptionbottom: "You can check out the small mikvahs solution to learn more about how mikvahrsvp can work for you."
    }
  ]

  return (
    <>
        <Header />
        <Banner 
          className= "live-dashboard-banner-contents"
          auto= {livedashboard}
          meta= {t("FullPretitle")}
          metabtn= {t("Dash")}
          title= {t("DashTitle")}
          description1= {t("DashHeroBody")}
          demo="get a quote"
          commit= ""
          trial= ""
          bannerimg= {dashboardBanner}
        />
        <Reception />
        <MikvahAbout
          title= {t("DashAttTitle")}
          description1= {t("DashAttBody")}
          description2= ""
          imgsrc= {dabout}
          bluedotcirclesrc= {daboutdotted}
          imgsrcbg= {daboutbg}
          redshape= {redshape}
          className= "dashboard-quote-sec"
          btnquote= "Start A QUOTE"
        />
        <OptionalDashboard />
        <DiffMikvahs 
          className= "dashboard-diff-sec"
          meta= {t("SmallCasePretitle")}
          heading= {t("SmallCaseTitle")}
          btn= {t("CtaReadCustomerStories")}
        />
        <Faq 
          className= "door-faq"
          accordionData= {accordionData}
        />
        <Nextlevel 
          className= "dashboard-next-level"
          meta= {t("DispCtaPretitle")}
          title= {t("DispCtaTitle")}
          description1= {t("DispCtaBody")}
          span1= ""
          span2= ""
          span3= ""
          btn1= {t('CtaPlan')}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default Dashboard