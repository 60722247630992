import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import NMikvahWatch from './NMikvahWatch/NMikvahWatch';
import Banner from '../../components/Banner/Banner';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import MikvahrsvpManagement from '../../components/MikvahManagement/MikvahrsvpManagement';
import managementbanner from '../../assets/home/management-banner.png';
import smikvah from '../../assets/home/s-mikvah.png';
import mmikvah from '../../assets/home/m-mikvah.png';
import lmikvah from '../../assets/home/l-mikvah.png';
import ngmikvahicon from '../../assets/home/ng-mikvah-icon.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import pinkdotted from '../../assets/home/pinkdotted.png';
import nmimg1 from '../../assets/home/nmimg1.png';
import nmimg2 from '../../assets/home/nmimg2.png';
import nmimg3 from '../../assets/home/nmimg3new.png';
import './NewMikvah.scss';
import { useTranslation} from 'react-i18next';
const NewMikvah = () => {
    const { t, i18n } = useTranslation();
    const NavData = [
        {
            eventKey: "first",
            imgsrc: smikvah,
            title: t("HomeSolutionsSmall"),
            description: t("HomeSolutionsSmallVisitors")
        },
        {
            eventKey: "second",
            imgsrc: mmikvah,
            title: t("HomeSolutionsMedium"),
            description: t("HomeSolutionsMediumVisitors")
        },
        {
            eventKey: "third",
            imgsrc: lmikvah,
            title: t("HomeSolutionsLarge"),
            description: t("HomeSolutionsLargeVisitors")
        }
    ]

    const workData = [
        {
            countNumber: "1",
            counttitle: "Custom Solutions",
            btntext: t("CtaSee"),
        },
        {
            countNumber: "2",
            counttitle: "Ultimate Guest Experience",
            btntext: t("CtaSee"),
        },
        {
            countNumber: "3",
            counttitle: "Upgraded Ambiance",
            btntext: t("CtaSee"),
        }
    ]

    const workDataDetail = [
        {
            countNumber: 1,
            meta: "Custom Solutions",
            counttitle: "Your Mikvah = your unique solutions",
            listtitle1: "",
            listitems1: [
                "We’ll work with you to find the perfect plan for your Mikvah’s needs",
                "Custom quotes based on prep rooms, boros, reception desks, etc.",
                "Elegant systems blend seamlessly into Mikvah design",
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            manageimg: nmimg1,
            reddotcircle: bluedotcircle,
            bluedotcircle: pinkdotted,
            className: "mng-nmikvah-img-sec1"
        },
        {
            countNumber: 2,
            meta: "The ultimate guest experience",
            counttitle: "Giving women the attention and care they deserve",
            listitems1: [
                "Create personalized reservations in under a minute",
                "Minimal to no wait times at Mikvah",
                "Customized prep experience with in-room displays",
                "Simplified communications between staff and patrons",
                "Optimized for patron discretion"
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            itembtn: t("CtaStart"),
            manageimg: nmimg2,
            reddotcircle: pinkdotted,
            bluedotcircle: pinkdotted,
            className: "mng-nmikvah-img-sec2"
        },
        {
            countNumber: 3,
            meta: "Upgraded ambiance",
            counttitle: "Maintain a calm, luxurious ambiance for all",
            listtitle1: "",
            listitems1: [
                "Night schedule is generated and prepared in advance",
                "Mikvah queue is automated for an orderly process",
                "Optional guidance system leads attendants to correct rooms without anxiety",
                "Private notes on patron’s accounts allow staff to be prepared for any special situation",
                "Quick turnaround allows staff to leave on time"
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            itembtn: t("CtaStart"),
            manageimg: nmimg3,
            reddotcircle: reddotcircle,
            bluedotcircle: pinkdotted,
            className: "mng-nmikvah-img-sec3"
        },
    ]

    const sitepoint = [
        "Reservation System",
        "Auto Scheduling",
        t("IntDisp"),
        "Live Dashboard",
    ]

    const newsitepoint = [
        "Guidance System",
        "Door Control",
        "Mikvah Website"
    ]

  return (
    <>
        <Header />
        <Banner 
          className= "New-mikvah-banner-contents"
          auto= {ngmikvahicon}
          meta= ""
          metabtn= "New Mikvahs"
          title= "Mazel Tov! It's a Mikvah"
          description1= "As a new construction, all of Mikvahrsvp’s options are open for you to use in your Mikvah! "
          description2= "Give your guests a luxurious experience, maintain a relaxed atmosphere for your staff, and outfit your Mikvah with the highest-tech, easiest-to-use management systems."
          demo={t("CtaStartWeb")}
          contactbtn="contact sales"
          bannerimg= {managementbanner}
          sitepoint= {sitepoint}
          newsitepoint= {newsitepoint}
        />
        <MikvahrsvpManagement 
            className= "new-mikvahmanagement"
            meta= {t("HomeBenefitsPretitle")}
            title= "Build your mikvah to the highest standard"
            description= "You have the plans, you have the vision. Gift your Mikvah the #1 solution for Mikvah management so it can function as well as it looks."
            workData= {workData}
            workDataDetail= {workDataDetail}
        />
        <NMikvahWatch />
        <DiffMikvahs 
          className= "nmikvah-diff-sec"
          meta= "STILL UNSURE IF IT IS RIGHT FOR YOU?"
          heading= "See how dozens of Small Mikvahs like yours benefitted from Mikvahrsvp"
          btn= {t("CtaReadCustomerStories")}
        />
        <MikvahPlans 
          span= "FIND THE RIGHT PLAN"
          title= "What size will your Mikvah be?"
          NavData= {NavData}
        />
        <Nextlevel 
          className= "Nmikvah-next-level"
          title= "Not sure where to start?"
          description1= "Let’s help you find your perfect solution."
          span1= "So you can relax knowing that your Mikvah is running smoothly."
          span2= "So your staff can relax knowing everything is taken care of."
          span3= "So the women you serve can relax knowing they are getting a top-tier experience optimized for their privacy and comfort."
          btn1= "contact sales"
          btn2= "view plans"
        />
        <Footer />
    </>
  )
}

export default NewMikvah