import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import rightarrow from '../../assets/home/right-arrow.png';
import './Experience.scss';

const Experience = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className={`exp-sec ${props.className}`}>
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport exp-wrapper' : 'exp-wrapper'}>
                    <div className="heading-sec">
                        <span>{props.meta}</span>
                        <h2>{props.heading}</h2>
                        <p className="description">{props.hedingdescription}</p>
                    </div>
                    {
                        props.ExperienceData.map((item, id) => (
                            <div className="exp-row d-flex align-items-center" key={id}>
                                <div className="exp-column left-text">
                                    <strong>{item.title}</strong>
                                    <p>{item.description1}</p>
                                    <p>{item.description2}</p>
                                    <div className="active-bullets">
                                        {
                                            [...Array(props.ExperienceData.length)].map((_, index) => (
                                                <span key={index} className={id === index ? 'span-active' : ''}>.</span>
                                            ))
                                        }
                                    </div>
                                    <div className="link-btn">
                                        <Link to='#'>
                                            <span>{item.linkbtn}</span>
                                            <img src={rightarrow} alt="rightarrow" />
                                        </Link>
                                    </div>
                                </div>
                                <div className={`exp-column right-img ${item.className}`}>
                                    <div className="static-img">
                                        <img src={item.imgsrc} alt={item.imgsrc} />
                                    </div>
                                    <img src={item.bluedotcirclesrc} alt={item.bluedotcirclesrc} className='rotate-item1' />
                                    <img src={item.randombluesrc} alt={item.randombluesrc} className='rotate-item3' />
                                    <img src={item.reddotcirclesrc} alt={item.reddotcirclesrc} className='rotate-item2' />
                                </div>
                            </div>
                        ))
                    }
                    <button className='transparent-button -using-box-models'>
                        <Link to="#">
                            <span>{props.btntext}</span>
                        </Link>
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Experience