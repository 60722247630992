import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import autocheck from '../../../assets/home/blue-check-icon.png';
import backendmbg from '../../../assets/home/backendmbg.png';
import './ManagendStaff.scss';
import { useTranslation } from 'react-i18next';

const ManagendStaff = () => {

    const {t, i18n} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className="manage-staff-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport manage-staff-tab-wrapper' : 'manage-staff-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t('ResBackendPretitle')}</span>
                        <h2>{t("ResBackendTitle")}</h2>
                    </div>
                    <div className="wrap-tab">
                        <div className="row bottom-col-row">
                            <div className="left-col">
                                <div className="tab-content">
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                                {t("ResBackendNotechTitle")}
                                            </h4>
                                            <p className="content-description">
                                                {t("ResBackendNotechBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                                {t("ResBackendCalendarTitle")}
                                            </h4>
                                            <p className="content-description">
                                                {t("ResBackendCalendarBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                                {t("ResBackendSchedTitle")}
                                            </h4>
                                            <p className="content-description">
                                                {t("ResBackendSchedBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                                {t("ResBackendEmployeeTitle")}
                                            </h4>
                                            <p className="content-description">
                                                {t("ResBackendEmployeeBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("ResBackendPayTitle")}
                                            </h4>
                                            <p className="content-description">
                                            {t("ResBackendPayBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("ResBackendUpsellTitle")}
                                            </h4>
                                            <p className="content-description">
                                            {t("ResBackendUpsellBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="tab-footer">
                                        <div className="menu-content">
                                            <Link to='#'>
                                                <span>START NOW</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-col">
                                <div className="right-col-wrap">
                                    <div className="hero-img bw-images">
                                        <img src={backendmbg} alt="backendmbg" className='bwimg' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ManagendStaff