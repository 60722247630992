import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import RmikvahWatch from './RMikvahWatch/RMikvahWatch';
import Banner from '../../components/Banner/Banner';
import DiffMikvahs from '../../components/DiffMikvahs/DiffMikvahs';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import MikvahPlans from '../../components/MikvahPlans/MikvahPlans';
import MikvahrsvpManagement from '../../components/MikvahManagement/MikvahrsvpManagement';
import phone from '../../assets/home/home-phone.png';
import banerchart from '../../assets/home/tablet-popout-new.png';
import smikvah from '../../assets/home/s-mikvah.png';
import mmikvah from '../../assets/home/m-mikvah.png';
import lmikvah from '../../assets/home/l-mikvah.png';
import rgmikvahicon from '../../assets/home/rg-mikvah-icon.png';
import bluedotcircle from '../../assets/home/blue-dot-circle.png';
import reddotcircle from '../../assets/home/red-dot-circle.png';
import dbluedotted from '../../assets/home/dbluedotted.png';
import rmimg1 from '../../assets/home/rmimg1new.png';
import rmimg2 from '../../assets/home/rmimg2new.png';
import rmimg3 from '../../assets/home/rmimg3new.png';
import './RetrofittingMikvah.scss';
import { useTranslation} from 'react-i18next';
const RetrofittingMikvah = () => {
    const { t, i18n } = useTranslation();
    const NavData = [
        {
            eventKey: "first",
            imgsrc: smikvah,
            title: t("HomeSolutionsSmall"),
            description: t("HomeSolutionsSmallVisitors")
        },
        {
            eventKey: "second",
            imgsrc: mmikvah,
            title: t("HomeSolutionsMedium"),
            description: t("HomeSolutionsMediumVisitors")
        },
        {
            eventKey: "third",
            imgsrc: lmikvah,
            title: t("HomeSolutionsLarge"),
            description: t("HomeSolutionsLargeVisitors")
        }
    ]

    const workData = [
        {
            countNumber: "1",
            counttitle: "Personalized Solutions",
            btntext: t("CtaSee"),
        },
        {
            countNumber: "2",
            counttitle: "Elevated Guest Experience",
            btntext: t("CtaSee"),
        },
        {
            countNumber: "3",
            counttitle: "Upgraded Ambiance",
            btntext: t("CtaSee"),
        }
    ]

    const workDataDetail = [
        {
            countNumber: 1,
            meta: "Personalized Solutions",
            counttitle: "Your Mikvah = your unique solutions",
            listtitle1: "",
            listitems1: [
                "Collaborate with our team to create a tailored plan for your Mikvah's specific requirements",
                "Custom quotes based on prep rooms, boros, reception desks, etc.",
                "Integrate elegant systems seamlessly into your Mikvah's design",
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            itembtn: "Get your custom quote",
            manageimg: rmimg1,
            reddotcircle: dbluedotted,
            bluedotcircle: bluedotcircle,
            className: "mng-rmikvah-img-sec1"
        },
        {
            countNumber: 2,
            meta: "Elevated guest experience",
            counttitle: "Put your guests first with unrivaled attention and care",
            listitems1: [
                "Create personalized reservations in under a minute",
                "Minimal to no wait times at Mikvah",
                "Customized prep experience with in-room displays",
                "Simplified communications between staff and patrons",
                "Prioritizes patron discretion and privacy"
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            itembtn: t("CtaStart"),
            manageimg: rmimg2,
            reddotcircle: reddotcircle,
            bluedotcircle: bluedotcircle,
            className: "mng-rmikvah-img-sec2"
        },
        {
            countNumber: 3,
            meta: "Upgraded ambiance",
            counttitle: "Maintain a tranquil atmosphere for all",
            listtitle1: "",
            listitems1: [
                "Night schedule is generated and prepared in advance",
                "Easier Mikvah queue creation",
                "Private notes on patron’s accounts allow staff to be prepared for",
                "any special situation",
                "Quicker turnaround allows staff to leave on time"
            ],
            listtitle2: "",
            listitems2: [
                "",
            ],
            itembtn: t("CtaStart"),
            manageimg: rmimg3,
            reddotcircle: dbluedotted,
            bluedotcircle: dbluedotted,
            className: "mng-rmikvah-img-sec3"
        },
    ]

    const sitepoint = [
        "Reservation System",
        "Auto Scheduling",
        t("IntDisp"),
        "Mikvah Website"
    ]

    return (
        <>
            <Header />
            <Banner 
                className= "retro-mikvah-banner-contents"
                auto= {rgmikvahicon}
                meta= ""
                metabtn= "Retrofitting Mikvahs"
                title= "Upgraded systems, made for your needs"
                description1= "Enhance the experience for guests and staff by streamlining every process. With our auto management system and helpful interactive displays, your staff can thrive in a relaxed environment, while guests enjoy a heightened sense of engagement, communication, and serenity!"
                demo={t("CtaStartWeb")}
                contactbtn="contact sales"
                bannerhomeimg= {banerchart}
                bannerimg= {phone}
                sitepoint= {sitepoint}
            />
            <MikvahrsvpManagement 
                className= "retro-mikvahmanagement"
                meta= {t("HomeBenefitsPretitle")}
                title= "The perfect fusion of technology and tradition"
                description= "Gift your Mikvah the #1 solution for Mikvah management so it can function to its maximum potential."
                workData= {workData}
                workDataDetail= {workDataDetail}
            />
            <RmikvahWatch />
            <DiffMikvahs 
                className= "nmikvah-diff-sec"
                meta= ""
                heading= "See how dozens of Small Mikvahs like yours benefitted from Mikvahrsvp"
                btn= {t("CtaReadCustomerStories")}
            />
            <MikvahPlans
                span= "FIND THE RIGHT PLAN"
                title= "What size is your Mikvah?"
                NavData= {NavData}
                className= "rmikvah-diff-sec"
            />
            <Nextlevel 
                className= "Nmikvah-next-level"
                title= "Not sure where to start?"
                description1= "Let’s help you find your perfect solution."
                span1= "So you can relax knowing that your Mikvah is running smoothly."
                span2= "So your staff can relax knowing everything is taken care of."
                span3= "So the women you serve can relax knowing they are getting a top-tier experience optimized for their privacy and comfort."
                btn1= "contact sales"
                btn2= "view plans"
            />
            <Footer />
        </>
    )
}

export default RetrofittingMikvah