import React from 'react';
import './MikvahWebsite.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/NewFooter/Footer';
import MikvahTab from './MikvahTabOptions/MikvahTab';
import Banner from '../../components/Banner/Banner';
import Faq from '../../components/FAQs/Faq';
import Nextlevel from '../../components/NextLevel/Nextlevel';
import autobanner from '../../assets/home/auto-banner.png';
import mikvahicon from '../../assets/home/mikvahicon-white.png';
import { useTranslation} from 'react-i18next';
const MikvahWebsite = () => {
  const { t, i18n } = useTranslation();
  const accordionData = [
    {
        title: t("WebFaq1Title"),
        descriptiontop: t("WebFaq1Body"),
        descriptionbottom: ""
    },
    {
      title: t("WebFaq2Title"),
      descriptiontop: t("WebFaq2Body"),
      descriptionbottom: ""
    },
    {
      title: t("WebFaq3Title"),
      descriptiontop: t("WebFaq3Body"),
      descriptionbottom: ""
    },
    {
      title: t("WebFaq4Title"),
      descriptiontop: t("WebFaq4Body"),
      descriptionbottom: ""
    }
]
const staticText = ' • Get a'

  return (
    <>
        <Header />
        <Banner 
          className= "mikvah-banner-contents"
          auto= {mikvahicon}
          meta= {t("OptionalPretitle")}
          metabtn= {t("MikWeb")}
          title= {t("WebTitle")}
          description1= {t("WebHeroBody")}
          demo={t('CtaDemoSite')}
          commit= {t("CtaSubNocom")} staticText
          getlink= "free trial"
          trial= " today!"
          bannerimg= {autobanner}
        />
        <MikvahTab />
        <Faq 
          className= "mikvah-faq"
          accordionData= {accordionData}
        />
        <Nextlevel 
          className= "mikvah-next-level"
          meta= {t("DispCtaPretitle")}
          title= "Start your personalized website today!"
          description1= "Your Mikvah is unique and your needs are unique, as well. We’re help to help you navigate our features to figure out the best plan for you."
          span1= ""
          span2= ""
          span3= ""
          btn1= {t("CtaStart")}
          btn2= "Contact Sales"
        />
        <Footer />
    </>
  )
}

export default MikvahWebsite