import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import autocheck from '../../../assets/home/auto-check.png';
import fullcapability from '../../../assets/home/full-capability.png';
import greendotted from '../../../assets/home/greendotted.png';
import bluedotted from '../../../assets/home/bluedotted.png';
import './DoorCapabilities.scss';
import { useTranslation } from 'react-i18next';

const DoorCapabilities = () => {

    const {t, i18n} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className="door-capability-sec">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport door-tab-wrapper' : 'door-tab-wrapper'}>
                    <div className="heading-sec">
                        <h2>{t("DoorCapTitle")}</h2>
                    </div>
                    <div className="wrap-tab">
                        <div className="row bottom-col-row">
                            <div className="left-col">
                                <div className="tab-content">
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("DoorCapUnlockTitle")}
                                            </h4>
                                            <p className="content-description">
                                            {t("DoorCapUnlockBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("DoorCapAutoTitle")}
                                            </h4>
                                            <p className="content-description">
                                            {t("DoorCapAutoBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("DoorCapElecTitle")}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("DoorCapKeyTitle")}
                                            </h4>
                                            <p className="content-description">
                                            {t("DoorCapKeyBody")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="content-items">
                                        <div className="content-icon">
                                            <img src={autocheck} alt="autocheck" />
                                        </div>
                                        <div className="content-text">
                                            <h4 className="content-heading">
                                            {t("DoorCapShabbosTitle")}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="tab-footer">
                                        <div className="menu-content">
                                            <Link to='#'>
                                                <span>START A QUOTE</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-col">
                                <div className="right-col-wrap">
                                    <div className="hero-img bw-images">
                                        <img src={fullcapability} alt="fullcapability" className='bwimg' />
                                        <img src={greendotted} alt="greendotted" className='greendotted' />
                                        <img src={bluedotted} alt="bluedotted" className='bluedotted' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DoorCapabilities