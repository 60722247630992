import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// const i18nLoader = async () => {
//   // Fetch translations from the API
//   const response = await fetch('/api/translations'); // Replace with your API endpoint
//   const translations = await response.json();

//   // Initialize i18next with the fetched translations
//   i18n
//     .use(Backend)
//     .use(LanguageDetector)
//     .init({
//       resources: translations,
//       fallbackLng: 'en',
//       debug: true, // Set to true to see console logs during development
//       interpolation: {
//         escapeValue: false, // React already safely escapes strings
//       },
//     });
// };

// export default i18nLoader;
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
  });
export default i18n;