import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/home/logo.png';
import './Footer.scss';
import { useTranslation} from 'react-i18next';
const Footer = () => {
    const { t, i18n } = useTranslation();
  return (
    <div className="footer-contents">
        <div className="container">
            <div className="footer-wrapper">
                <footer className='footer-content'>
                    <div className="footer-item item1">
                        <div className="footer-img">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="footer-item item2">
                        <div className="footer-content-detail">
                            <h5 className='item-title'>Plans</h5>
                            <div className="items-sec">
                                <div className="nav-items-list">
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>essentials</h6>
                                        <ul>
                                            <li>
                                                <Link to="/Reservation-system">
                                                    <span>Reservation System</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/Auto-scheduling">
                                                    <span>Auto Scheduling</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>Full Service</h6>
                                        <ul>
                                            <li>
                                                <Link to="/live-dashboard">
                                                    <span>Live Dashboard</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/guidance-system">
                                                    <span>Guidance System</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/door-control">
                                                    <span>Door Control</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="nav-items-list">
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>Premium</h6>
                                        <ul>
                                            <li>
                                                <Link to="/Interactive-display">
                                                    <span>{t("IntDisp")}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>Optional features</h6>
                                        <ul>
                                            <li>
                                                <Link to="/mikvah-website">
                                                    <span>Mikvah Website</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <span>Men's Mikvah</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <span>Membership Option</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-item item3">
                        <div className="footer-content-detail">
                            <h5 className='item-title'>Solutions</h5>
                            <div className="items-sec solution-items">
                                <div className="nav-items-list">
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>by Size</h6>
                                        <ul>
                                            <li>
                                                <Link to="/small-mikvah">
                                                    <span>{t("HomeSolutionsSmall")}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/medium-mikvah">
                                                    <span>{t("HomeSolutionsMedium")}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/large-mikvah">
                                                    <span>{t("HomeSolutionsLarge")}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="nav-items-list">
                                    <div className="nav-items-content">
                                        <h6 className='nav-item-heading'>by Need</h6>
                                        <ul>
                                            <li>
                                                <Link to="/new-mikvah">
                                                    <span>New Mikvahs</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/existing-mikvah">
                                                    <span>Existing Mikvahs</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/retrofitting-mikvah">
                                                    <span>Retrofitting Mikvahs</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-item item4">
                        <div className="footer-content-detail">
                            <h5 className='item-title'>Explore more</h5>
                            <div className="items-sec">
                                <div className="nav-items-list">
                                    <div className="nav-items-content">
                                        <ul>
                                            <li>
                                                <Link>
                                                    <span>{t("CtaViewAll")} plans</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link>
                                                    <span>Pricing</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="nav-items-content">
                                        <ul>
                                            <li>
                                                <Link>
                                                    <span>Customer Stories</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link>
                                                    <span>About us</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
  )
}

export default Footer