import React from 'react';
import './InteractiveStory.scss';
import { Link } from 'react-router-dom';
import tabslogomikhvah from '../../../assets/home/tabs-logo-mikhvah.png';
import rightarrowwhite from '../../../assets/home/right-arrow-white.png';
import rightarrowdark from '../../../assets/home/right-arrow-dark.png';
import { useTranslation} from 'react-i18next';
const InteractiveStory = () => {
    const { t, i18n } = useTranslation();
    return (
        <section className="Story-sec">
            <div className="container">
                <div className="tab-wrapper">
                    <div className="logo-banner">
                        <div className="logo-banner-tabs">
                            <div className="banner-logo-row d-flex align-items-end">
                                <div className="banner-logo-column w-50">
                                    <Link to="#">
                                        <img src={tabslogomikhvah} alt="tabslogomikhvah" />
                                    </Link>
                                    <p>
                                        “When creating our Mikvah, we wanted to give it the best. Along with the excellent 
                                        way for women to make reservations, the tablets around the Mikvah was a real 
                                        gamechanger. Our women love the ability to set a timer and change the music 
                                        and let us know they’re ready to toivel in a modest way.”</p>
                                </div>
                                <div className="banner-logo-column w-50 text-end">
                                    <Link to="#" className='read-btn'>
                                        <span>{t("CtaRead")}</span>
                                        <img src={rightarrowwhite} alt="rightarrowwhite" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='transparent-button'>
                        <Link to="#">
                            <span>{t("CtaFullStory")}</span>
                            <img src={rightarrowdark} alt="rightarrowdark" />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InteractiveStory