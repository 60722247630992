import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import tabslogo from '../../assets/home/tabs-logo-mikhvah.png';
import rightarrowwhite from '../../assets/home/right-arrow-white.png';
import bgimg1 from '../../assets/home/bimg1.png';
import bgimg2 from '../../assets/home/bimg2.png';
import bgimg3 from '../../assets/home/bimg3.png';
import bgimg4 from '../../assets/home/bimg4.png';
import bgimg5 from '../../assets/home/bimg5.png';
import bgimg6 from '../../assets/home/bimg6.png';
import reservation from '../../assets/home/reservation.png';
import auto from '../../assets/home/Auto.png';
import interactive from '../../assets/home/Interactive.png';
import guidance from '../../assets/home/guidance.png';
import door from '../../assets/home/door.png';
import msite from '../../assets/home/m-website.png';
import live from '../../assets/home/live.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './BrowseStories.scss';
import { useTranslation} from 'react-i18next';
const BrowseStories = () => {

    const data = [
        { id: 0, label: "Sort by size 1" },
        { id: 1, label: "Sort by size 2" },
        { id: 2, label: "Sort by size 3" },
    ];

    const data1 = [
        { id: 0, label: "Sort by stage 1" },
        { id: 1, label: "Sort by stage 2" },
        { id: 2, label: "Sort by stage 3" },
    ];

    const data2 = [
        { id: 0, label: "Sort by features 1" },
        { id: 1, label: "Sort by features 2" },
        { id: 2, label: "Sort by features 3" },
    ];
    
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [isOpen1, setIsOpen1] = useState(false);
    const [selectedOption2, setSelectedOption2] = useState(null);
    const [isOpen2, setIsOpen2] = useState(false);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleDropdownToggle1 = () => {
        setIsOpen1(!isOpen1);
    };
    const handleDropdownToggle2 = () => {
        setIsOpen2(!isOpen2);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    const handleOptionSelect1 = (option) => {
        setSelectedOption1(option);
        setIsOpen1(false);
    };
    const handleOptionSelect2 = (option) => {
        setSelectedOption2(option);
        setIsOpen2(false);
    };
    const { t, i18n } = useTranslation();
    return (
        <section className="browse-sec">
            <div className="container">
                <div className='browse-tab-wrapper'>
                    <div className="heading-sec">
                        <h2>Browse Customer Stories</h2>
                        <div className="seclection-area">
                            <form>
                                <div className="dropdown">
                                    <div className="dropdown-header" onClick={handleDropdownToggle}>
                                        {selectedOption ? selectedOption.label : "Sort by size"}
                                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </div>
                                    {isOpen && (
                                        <div className=" dropdown-body">
                                            {data.map((option) => (
                                                <div
                                                    key={option.id}
                                                    className="dropdown-item"
                                                    onClick={() => handleOptionSelect(option)}
                                                >
                                                    {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="dropdown">
                                    <div className="dropdown-header" onClick={handleDropdownToggle1}>
                                        {selectedOption1 ? selectedOption1.label : "Sort by stage"}
                                        {isOpen1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </div>
                                    {isOpen1 && (
                                        <div className=" dropdown-body">
                                            {data1.map((option) => (
                                                <div
                                                    key={option.id}
                                                    className="dropdown-item"
                                                    onClick={() => handleOptionSelect1(option)}
                                                >
                                                    {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="dropdown">
                                    <div className="dropdown-header" onClick={handleDropdownToggle2}>
                                        {selectedOption2 ? selectedOption2.label : "Sort by features"}
                                        {isOpen2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </div>
                                    {isOpen2 && (
                                        <div className=" dropdown-body">
                                            {data2.map((option) => (
                                                <div
                                                    key={option.id}
                                                    className="dropdown-item"
                                                    onClick={() => handleOptionSelect2(option)}
                                                >
                                                {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="content-sec">
                        <div className="content-row">
                            <div className="left-sec">
                                <div className="content-wrap">
                                    <div className="content-tab" style={{ backgroundImage: `url(${bgimg1})`}}>
                                        <div className="tab-pane">
                                            <div className="top-content">
                                                <div className="tab-logo">
                                                    <img src={tabslogo} alt="tabslogo" />
                                                </div>
                                                <p>Crown Heights, NY</p>
                                                <span>300+ visitors</span>
                                            </div>
                                            <div className="bottom-content">
                                                <div className="system-use">
                                                    <p>Systems used:</p>
                                                    <div className="img-group">
                                                        <div className="left-group">
                                                            <img src={reservation} alt="reservation" width={24} height={24} />
                                                            <img src={live} alt="live" width={34} height={24} />
                                                            <img src={interactive} alt="interactive" width={28} height={26} />
                                                            <img src={door} alt="door" width={20} height={27} />
                                                        </div>
                                                        <div className="right-group">
                                                            <img src={auto} alt="auto" width={25} height={25} />
                                                            <img src={guidance} alt="guidance" width={30} height={30} />
                                                            <img src={msite} alt="msite" width={27} height={24} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="link-btn">
                                                    <Link to="/customer-stories/mikvah-story">
                                                        <span>{t("CtaRead")}</span>
                                                        <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-sec">
                                <div className="right-content-sec">
                                    <div className="content-wrap">
                                        <div className="content-tab" style={{ backgroundImage: `url(${bgimg2})`}}>
                                            <div className="tab-pane">
                                                <div className="top-content">
                                                    <div className="tab-logo">
                                                        <img src={tabslogo} alt="tabslogo" />
                                                    </div>
                                                    <p>Crown Heights, NY</p>
                                                    <span>300+ visitors</span>
                                                </div>
                                                <div className="bottom-content">
                                                    <div className="system-use">
                                                        <p>Systems used:</p>
                                                    </div>
                                                    <div className="link-btn link-btn-dark">
                                                        <Link>
                                                            <span>{t("CtaRead")}</span>
                                                            <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrap">
                                        <div className="content-tab" style={{ backgroundImage: `url(${bgimg3})`}}>
                                            <div className="tab-pane">
                                                <div className="top-content">
                                                    <div className="tab-logo">
                                                        <img src={tabslogo} alt="tabslogo" />
                                                    </div>
                                                    <p>Crown Heights, NY</p>
                                                    <span>300+ visitors</span>
                                                </div>
                                                <div className="bottom-content">
                                                    <div className="system-use">
                                                        <p>Systems used:</p>
                                                    </div>
                                                    <div className="link-btn">
                                                        <Link>
                                                            <span>{t("CtaRead")}</span>
                                                            <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="left-sec">
                                <div className="content-wrap">
                                    <div className="content-tab" style={{ backgroundImage: `url(${bgimg6})`}}>
                                        <div className="tab-pane">
                                            <div className="top-content">
                                                <div className="tab-logo">
                                                    <img src={tabslogo} alt="tabslogo" />
                                                </div>
                                                <p>Crown Heights, NY</p>
                                                <span>300+ visitors</span>
                                            </div>
                                            <div className="bottom-content">
                                                <div className="system-use">
                                                    <p>Systems used:</p>
                                                    <div className="img-group">
                                                        <div className="left-group">
                                                            <img src={reservation} alt="reservation" width={24} height={24} />
                                                            <img src={live} alt="live" width={34} height={24} />
                                                            <img src={interactive} alt="interactive" width={28} height={26} />
                                                            <img src={door} alt="door" width={20} height={27} />
                                                        </div>
                                                        <div className="right-group">
                                                            <img src={auto} alt="auto" width={25} height={25} />
                                                            <img src={guidance} alt="guidance" width={30} height={30} />
                                                            <img src={msite} alt="msite" width={27} height={24} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="link-btn link-btn-dark">
                                                    <Link>
                                                        <span>{t("CtaRead")}</span>
                                                        <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-sec">
                                <div className="right-content-sec">
                                    <div className="content-wrap">
                                        <div className="content-tab" style={{ backgroundImage: `url(${bgimg4})`}}>
                                            <div className="tab-pane">
                                                <div className="top-content">
                                                    <div className="tab-logo">
                                                        <img src={tabslogo} alt="tabslogo" />
                                                    </div>
                                                    <p>Crown Heights, NY</p>
                                                    <span>300+ visitors</span>
                                                </div>
                                                <div className="bottom-content">
                                                    <div className="system-use">
                                                        <p>Systems used:</p>
                                                    </div>
                                                    <div className="link-btn link-btn-dark">
                                                        <Link>
                                                            <span>{t("CtaRead")}</span>
                                                            <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrap">
                                        <div className="content-tab" style={{ backgroundImage: `url(${bgimg5})`}}>
                                            <div className="tab-pane">
                                                <div className="top-content">
                                                    <div className="tab-logo">
                                                        <img src={tabslogo} alt="tabslogo" />
                                                    </div>
                                                    <p>Crown Heights, NY</p>
                                                    <span>300+ visitors</span>
                                                </div>
                                                <div className="bottom-content">
                                                    <div className="system-use">
                                                        <p>Systems used:</p>
                                                    </div>
                                                    <div className="link-btn">
                                                        <Link>
                                                            <span>{t("CtaRead")}</span>
                                                            <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrowseStories