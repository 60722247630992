import React from 'react';
import WatchInAction from '../../../components/WatchInAction/WatchInAction';
import './LMikvahWatch.scss'
import { useTranslation } from 'react-i18next';

const LMikvahWatch = () => {

  const { t, i18n } = useTranslation();
  return (
    <section className='lmwatch-section'>
        <div className="container">
            <WatchInAction
              className= "LargeWatchIn"
              watchtitle= {t("LargVidTitle")}
            />
        </div>
    </section>
  )
}

export default LMikvahWatch