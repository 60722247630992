import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Features from '../../components/FeaturesAll/Features';
import './MikvahPlans.scss';

const MikvahPlans = (props) => {
    return (
        <section className={`mikvahplan-sec ${props.className}`}>
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>{props.span}</span>
                        <h2>{props.title}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='plans-tab-row'>
                            <Col className='top-col'>
                                <Nav variant="pills" className="nav-wrap">
                                    {
                                        props.NavData.map((item, id) => (
                                            <Nav.Item key={id}>
                                                <Nav.Link eventKey={item.eventKey}>
                                                    <div className="nav-content">
                                                        <div className="nav-wrap">
                                                            <div className="nav-logo frst-logo">
                                                                <img src={item.imgsrc} alt={item.imgsrc} />
                                                            </div>
                                                            <h4 className="nav-title">
                                                                {item.title}
                                                            </h4>
                                                            <p className="nav-description">
                                                                {item.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))
                                    }
                                </Nav>
                            </Col>
                            <Col className='bottom-col'>
                                <div className="recommend-text">
                                    <span>WE RECOMMEND...</span>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Features 
                                            className= "existing-newMikvah-feature newMikvah-feature"
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Features 
                                            className= "existing-Mikvah-feature"
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Features
                                            className= "retrofit-Mikvah-feature"
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}

export default MikvahPlans