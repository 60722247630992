import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.scss';
import Home from './pages/Home/Home';
import Reservation from './pages/ReservationSystem/Reservation';
import Scheduling from './pages/AutoScheduling/Sheduling';
import InteractiveDisplay from "./pages/InteractiveDisplay/InteractiveDisplay";
import Dashboard from "./pages/LiveDashboard/Dashboard";
import GuidanceSystem from "./pages/GuidanceSystem/GuidanceSystem";
import DoorControl from "./pages/DoorControl/DoorControl";
import MikvahWebsite from "./pages/MikvahWebsite/MikvahWebsite";
import SmallMikvah from "./pages/SmallMikvahs/SmallMikvah";
import MediumMikvah from "./pages/MediumMikvahs/MediumMikvah";
import LargeMikvah from "./pages/LargeMikvahs/LargeMikvah";
import MikvahPlans from "./pages/MikvahPlans/MikvahPlans";
import ExistingMikvah from "./pages/ExistingMikvahs/ExistingMikvah";
import NewMikvah from "./pages/NewMikvahs/NewMikvah";
import RetrofittingMikvah from "./pages/RetrofittingMikvahs/RetrofittingMikvah";
import CustomerStories from "./pages/CustomerStories/CustomerStories";
import MikvahStory from "./pages/MikvahStory/MikvahStory";
import About from "./pages/About/About";
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
// ...
function App() {
  const { t, i18n } = useTranslation();
  return (
    <>
    
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Reservation-system" element={<Reservation />} />
            <Route path="/Auto-scheduling" element={<Scheduling />} />
            <Route path="/Interactive-display" element={<InteractiveDisplay />} />
            <Route path="/live-dashboard" element={<Dashboard />} />
            <Route path="/guidance-system" element={<GuidanceSystem />} />
            <Route path="/door-control" element={<DoorControl />} />
            <Route path="/mikvah-website" element={<MikvahWebsite />} />
            <Route path="/small-mikvah" element={<SmallMikvah />} />
            <Route path="/medium-mikvah" element={<MediumMikvah />} />
            <Route path="/large-mikvah" element={<LargeMikvah />} />
            <Route path="/mikvah-plans" element={<MikvahPlans />} />
            <Route path="/existing-mikvah" element={<ExistingMikvah />} />
            <Route path="/new-mikvah" element={<NewMikvah />} />
            <Route path="/retrofitting-mikvah" element={<RetrofittingMikvah />} />
            <Route path="/customer-stories" element={<CustomerStories />} />
            <Route path="/customer-stories/mikvah-story" element={<MikvahStory />} />
            <Route path="/about" element={<About />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

// export default App;
export default function WrappedApp() {
  return (
    <Suspense >
      <App />
    </Suspense>
  )
}
