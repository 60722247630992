import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import rightarrowimg from '../../assets/home/right-arrow-dark.png';
import rightarrow from '../../assets/home/right-arrow.png';
import './MikvahrsvpManagement.scss';

const MikvahrsvpManagement = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className={`management-sec ${props.className}`}>
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport manage-tab-wrapper' : 'manage-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{props.meta}</span>
                        <h2>{props.title}</h2>
                        <p>{props.description}</p>
                    </div>
                    <div className="experience-contents">
                        <div className="experience-items">
                            {
                                props.workData.map((item, id) => (
                                    <div className="experience-item" key={id}>
                                        <div className="experience-wrap">
                                            <div className="experience-details">
                                                <div className="count-number">
                                                    <h4>{item.countNumber}</h4>
                                                </div>
                                                <div className="count-title">
                                                    <h4>{item.counttitle}</h4>
                                                </div>
                                                <div className="feature-btn">
                                                    <Link to="/">
                                                        <span>{item.btntext}</span>
                                                        <img src={rightarrowimg} alt="rightarrowimg" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="experience-contents-items-details">
                        <div className="contents-items-wrap">
                            {
                                props.workDataDetail.map((item, id) => (
                                    <div className="content-items" key={id}> 
                                        <div className="left-content">
                                            <div className="content-text">
                                                <div className="content-count-number">
                                                    <h4>{item.countNumber}</h4>
                                                </div>
                                                <div className="content-count-meta">
                                                    <span>{item.meta}</span>
                                                </div>
                                                <div className="content-count-title">
                                                    <h4>
                                                        {item.counttitle}
                                                    </h4>
                                                </div>
                                                <div className="content-description">
                                                    <h3>{item.listtitle1}</h3>
                                                    {
                                                        item.listitems1.map((itemlist, id) => (
                                                            <ul key={id}>  
                                                                <li>{itemlist}</li>
                                                            </ul>
                                                        ))
                                                    }
                                                </div>
                                                <div className="content-description content-description-none">
                                                    <h3>{item.listtitle2}</h3>
                                                    {
                                                        item.listitems2.map((itemlists, id) => (
                                                            <ul key={id}>  
                                                                <li>{itemlists}</li>
                                                            </ul>
                                                        ))
                                                    }
                                                </div>
                                                <div className="link-btn">
                                                    <Link to="/">
                                                        <span>{item.itembtn}</span>
                                                        <img src={rightarrow} alt="rightarrow" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right-content">
                                            <div className={`content-img ${item.className}`}>
                                                <img src={item.manageimg} alt={item.manageimg} className='static-img' />
                                                <img src={item.randomblue} alt={item.randomblue} className='rotate-item3' />
                                                <img src={item.reddotcircle} alt={item.reddotcircle} className='rotate-item2' />
                                                <img src={item.bluedotcircle} alt={item.bluedotcircle} className='rotate-item1' />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MikvahrsvpManagement