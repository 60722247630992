import React from 'react';
import './OptionalDashboard.scss';
import { Link } from 'react-router-dom';
import guidanceicon from '../../../assets/home/guidance-white.png';
import dooricon from '../../../assets/home/door-white.png';
import { useTranslation } from 'react-i18next';

const OptionalDashboard = () => {
    const {t, i18n} = useTranslation();
    return (
        <section className="optional-dashboard-sec">
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>{t("DashExtraPretitle")}</span>
                        <h2>{t("DashExtraTitle")}</h2>
                    </div>
                    <div className="booking-options">
                        <div className="d-flex booking-row">
                            <div className="booking-column">
                                <div className="booking-text">
                                    <h3 className='booking-title'>{t("DashExtraEntTitle")}</h3>
                                    <p className="booking-description">
                                    {t("DashExtraEntBody")}
                                    </p>
                                </div>
                                <ul>
                                    <li>{t("DashExtraEntBullet1")}</li>
                                    <li>{t("DashExtraEntBullet2")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <div className="booking-text">
                                    <h3 className='booking-title'>{t("DashExtraBorosTitle")}</h3>
                                    <p className="booking-description">
                                    {t("DashExtraBorosBody")}
                                    </p>
                                </div>
                                <ul>
                                    <li>{t("DashExtraBorosBullet1")}</li>
                                    <li>{t("DashExtraBorosBullet2")}</li>
                                </ul>
                            </div>
                            <div className="booking-column">
                                <div className="booking-text">
                                    <h3 className='booking-title'>{t("DashExtraCleanTitle")}</h3>
                                    <p className="booking-description">
                                    {t("DashExtraCleanBody")}
                                    </p>
                                </div>
                                <ul>
                                    <li>{t("DashExtraCleanBullet1")}</li>
                                    <li>{t("DashExtraCleanBullet2")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-sec">
                        <h2>{t("FullSeeMoreTitle")}</h2>
                        <div className="button-sec">
                            <Link to='#'>
                                <img src={guidanceicon} alt="guidanceicon" />
                                <span>{t("GuidSys")}</span>
                            </Link>
                            <Link to='#'>
                                <img src={dooricon} alt="dooricon" />
                                <span>{t("DoorCon")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OptionalDashboard