import React from 'react';
import { Link } from 'react-router-dom';
import spotlight from '../../assets/home/spotlight.png';
import mcalendar from '../../assets/home/m-calendar.png';
import './MikvahGroups.scss';

const MikvahGroups = () => {
    return (
        <>
            <section className="mikvh-group-sec">
                <div className="container">
                    <div className="mikvah-group-wrapper">
                        <div className="mikvah-group-contents">
                            <div className="top-heading">
                                <h2>Mikvahrsvp is brought to you by</h2>
                            </div>
                            <div className="mikvah-group-cards-wrapper">
                                <div className="card-item">
                                    <div className="card-items">
                                        <div className="card-img">
                                            <img src={spotlight} alt="spotlight" />
                                        </div>
                                        <div className="card-text">
                                            <p>
                                                Designed by Spotlight Design, with over 25 
                                                years experience of providing and creating 
                                                beautiful solutions to the branding, design 
                                                and marketing needs of its customers
                                            </p>
                                            <Link to='javascript:void(0)'onClick={() => window.location = 'mailto:spotlightdesign.com'}>
                                                <span>spotlightdesign.com</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-items">
                                        <div className="card-img">
                                            <img src={mcalendar} alt="mcalendar" />
                                        </div>
                                        <div className="card-text">
                                            <p>
                                                Brought to you by MikvahCalendar.com, 
                                                whose simple and elegant system responds 
                                                to the needs of today’s mikvah users
                                            </p>
                                            <Link to='javascript:void(0)'onClick={() => window.location = 'mailto:mikvahcalendar.com'}>
                                                <span>mikvahcalendar.com</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MikvahGroups