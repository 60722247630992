import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import guidancetabimg from '../../../assets/home/guide-tab-img.png';
import darkdotcircle from '../../../assets/home/dark-dot-circle.png';
import keys from '../../../assets/home/key.png';
import shine from '../../../assets/home/shine.png';
import choice from '../../../assets/home/choice.png';
import guidance from '../../../assets/home/guidance.png';
import './GuidanceHelp.scss';
import { useTranslation } from 'react-i18next';

const GuidanceHelp = () => {

    const {t, i18n} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      const currentRef = ref.current;
  
      if (currentRef) {
        observer.observe(currentRef);
      }
  
      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, []);

    return (
        <section className="tab-sec help">
            <div className="container">
                <div ref={ref} className={isVisible ? 'in-viewport guide-tab-wrapper' : 'guide-tab-wrapper'}>
                    <div className="heading-sec">
                        <span>{t("GuidHowPretitle")}</span>
                        <h2>{t("GuidHowTitle")}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='guidance-tab-row'>
                            <Col className='top-col'>
                                <Nav variant="pills" className="nav-wrap">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <div className="nav-content">
                                                <div className="nav-wrap">
                                                    <h4 className="nav-title">
                                                    {t("PatronToggle")}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <div className="nav-content">
                                                <div className="nav-wrap">
                                                    <h4 className="nav-title">
                                                    {t("AttendantToggle")}
                                                    </h4>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col className='bottom-col'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="wrap-tab">
                                            <div className="row bottom-col-row">
                                                <div className="left-col">
                                                    <div className="tab-content">
                                                        <div className="content-items">
                                                            <div className="content-icon">
                                                                <img src={keys} alt="keys" />
                                                            </div>
                                                            <div className="content-text">
                                                                <h4 className="content-heading">
                                                                {t("GuidHowPatron1Title")}
                                                                </h4>
                                                                <p className="content-description">
                                                                {t("GuidHowPatron1Body")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="content-items">
                                                            <div className="content-icon">
                                                                <img src={shine} alt="shine" />
                                                            </div>
                                                            <div className="content-text">
                                                                <h4 className="content-heading">
                                                                {t("GuidHowPatron2Title")}
                                                                </h4>
                                                                <p className="content-description">
                                                                {t("GuidHowPatron2Body")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-footer">
                                                            <div className="menu-content">
                                                                <Link to='#'>
                                                                    <span>START A QUOTE</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-col">
                                                    <div className="right-col-wrap">
                                                        <div className="hero-img bp-images">
                                                            <img src={guidancetabimg} alt="guidancetabimg" className='bwimg' />
                                                            <img src={darkdotcircle} alt="darkdotcircle" className='greendotted' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="wrap-tab">
                                            <div className="row bottom-col-row">
                                                <div className="left-col">
                                                    <div className="tab-content">
                                                        <div className="content-items">
                                                            <div className="content-icon">
                                                                <img src={guidance} alt="guidance" />
                                                            </div>
                                                            <div className="content-text">
                                                                <h4 className="content-heading">
                                                                {t("GuidHowAtt1Title")}
                                                                </h4>
                                                                <p className="content-description">
                                                                {t("GuidHowAtt1Body")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="content-items">
                                                            <div className="content-icon">
                                                                <img src={choice} alt="choice" />
                                                            </div>
                                                            <div className="content-text">
                                                                <h4 className="content-heading">
                                                                {t("GuidHowAtt2Title")}
                                                                </h4>
                                                                <p className="content-description">
                                                                {t("GuidHowAtt2Body")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-footer">
                                                            <div className="menu-content">
                                                                <Link to='#'>
                                                                    <span>START A QUOTE</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-col">
                                                    <div className="right-col-wrap">
                                                        <div className="hero-img ba-images">
                                                            <img src={guidancetabimg} alt="guidancetabimg" className='bwimg' />
                                                            <img src={darkdotcircle} alt="darkdotcircle" className='bluedotted' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )
}

export default GuidanceHelp