import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import mikvahester from '../../assets/home/mikvah-ester.svg';
import themikvahblack from '../../assets/home/the-mikvah-black.svg';
import mikvahteaneck from '../../assets/home/mikvah-teaneck.svg';
import mikvahhmh from '../../assets/home/mikvah-hmh.jpg';
import tabslogomikhvah from '../../assets/home/tabs-logo-mikhvah.png';
import rightarrowwhite from '../../assets/home/right-arrow-white.png';
import rightarrowdark from '../../assets/home/right-arrow-dark.png';
import './DiffMikvahs.scss';
import { useTranslation} from 'react-i18next';
const DiffMikvahs = (props) => {
    const { t, i18n } = useTranslation();
    const Tabitems = [
        {
            bgimgsrc: tabslogomikhvah,
            description: "“As a mikvah lady for many years, mikvahrsvp is a refreshing solution for our clients and staff. It’s easy for the women to set up customized appointments, and easy on our end to fulfill the requests.”",
            linkbtn: t("CtaRead"),
            btnimgsrc: rightarrowwhite,
            keyname: 'first'
        },
        {
            bgimgsrc: tabslogomikhvah,
            description: "“As a mikvah lady for many years, mikvahrsvp is a refreshing solution for our clients and staff. It’s easy for the women to set up customized appointments, and easy on our end to fulfill the requests.”",
            linkbtn: t("CtaRead"),
            btnimgsrc: rightarrowwhite,
            keyname: 'second'
        },
        {
            bgimgsrc: tabslogomikhvah,
            description: "“As a mikvah lady for many years, mikvahrsvp is a refreshing solution for our clients and staff. It’s easy for the women to set up customized appointments, and easy on our end to fulfill the requests.”",
            linkbtn: t("CtaRead"),
            btnimgsrc: rightarrowwhite,
            keyname: 'third'
        },
        {
            bgimgsrc: tabslogomikhvah,
            description: "“As a mikvah lady for many years, mikvahrsvp is a refreshing solution for our clients and staff. It’s easy for the women to set up customized appointments, and easy on our end to fulfill the requests.”",
            linkbtn: t("CtaRead"),
            btnimgsrc: rightarrowwhite,
            keyname: 'fourth'
        },
    ]

    const Tabitemslink = [
        {
            linkimg: mikvahester,
            keyname: 'first',
        },
        {
            linkimg: themikvahblack,
            keyname: 'second',
        },
        {
            linkimg: mikvahteaneck,
            keyname: 'third',
        },
        {
            linkimg: mikvahhmh,
            keyname: 'fourth',
        }
    ]
    return (
        <section className={`tab-sec ${props.className}`}>
            <div className="container">
                <div className="tab-wrapper">
                    <div className="heading-sec">
                        <span>{props.meta}</span>
                        <h2>{props.heading}</h2>
                    </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row className='diff-tab-row'>
                            <Col className='bottom-col'>
                                <Tab.Content className="logo-tab">
                                    {
                                        Tabitems.map((item, id) => (
                                            <Tab.Pane eventKey={item.keyname} key={id}>
                                                <div className="logo-banner-tabs">
                                                    <div className="banner-logo-row d-flex align-items-end">
                                                        <div className="banner-logo-column w-50">
                                                            <Link to="#" className='tab-logo-img'>
                                                                <img src={item.bgimgsrc} alt={item.bgimgsrc} />
                                                            </Link>
                                                            <p>{item.description}</p>
                                                        </div>
                                                        <div className="banner-logo-column w-50 text-end">
                                                            <Link to="#">
                                                                <span>{item.linkbtn}</span>
                                                                <img src={item.btnimgsrc} alt={item.btnimgsrc} />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        ))
                                    }
                                </Tab.Content>
                            </Col>
                            <Col className='top-col bottom-tab-button'>
                                <Nav variant="pills" className="nav-wrap">
                                    {
                                        Tabitemslink.map((item, id) => (
                                            <Nav.Item key={id}>
                                                <Nav.Link eventKey={item.keyname}>
                                                    <div className="nav-content">
                                                        <div className="nav-wrap">
                                                            <div className="nav-logo frst-logo">
                                                                <img src={item.linkimg} alt={item.linkimg} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))
                                    }
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                    <div className="transparent-btn">
                        <Link to='#'>
                            <span>READ CUSTOMER STORIES</span>
                            <img src={rightarrowdark} alt="rightarrowdark" />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DiffMikvahs