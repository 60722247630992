import React from 'react';
import { Link } from 'react-router-dom';
import bgimg2 from '../../../assets/home/bimg2.png';
import bgimg3 from '../../../assets/home/bimg3.png';
import tabslogo from '../../../assets/home/tabs-logo-mikhvah.png';
import rightarrowwhite from '../../../assets/home/right-arrow-white.png';
import './MoreStories.scss';
import { useTranslation} from 'react-i18next';
const MoreStories = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <section className="more-stories">
                <div className="container">
                    <div className="more-stories-wrapper">
                        <div className="heading-sec">
                            <h2>More Customer Stories</h2>
                        </div>
                        <div className="stories-content">
                            <div className="stories-content-flex">
                                <div className="content-wrap">
                                    <div className="content-tab" style={{ backgroundImage: `url(${bgimg2})`}}>
                                        <div className="tab-pane">
                                            <div className="top-content">
                                                <div className="tab-logo">
                                                    <img src={tabslogo} alt="tabslogo" />
                                                </div>
                                                <p>Crown Heights, NY</p>
                                                <span>300+ visitors</span>
                                            </div>
                                            <div className="bottom-content">
                                                <div className="system-use">
                                                    <p>Systems used:</p>
                                                </div>
                                                <div className="link-btn link-btn-dark">
                                                    <Link>
                                                        <span>{t("CtaRead")}</span>
                                                        <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-wrap">
                                    <div className="content-tab" style={{ backgroundImage: `url(${bgimg3})`}}>
                                        <div className="tab-pane">
                                            <div className="top-content">
                                                <div className="tab-logo">
                                                    <img src={tabslogo} alt="tabslogo" />
                                                </div>
                                                <p>Crown Heights, NY</p>
                                                <span>300+ visitors</span>
                                            </div>
                                            <div className="bottom-content">
                                                <div className="system-use">
                                                    <p>Systems used:</p>
                                                </div>
                                                <div className="link-btn">
                                                    <Link>
                                                        <span>{t("CtaRead")}</span>
                                                        <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MoreStories