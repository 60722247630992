import React from 'react';
import { Link } from 'react-router-dom';
import storybanner from '../../assets/home/storybanner.png';
import tabslogo from '../../assets/home/tabs-logo-mikhvah.png';
import rightarrowwhite from '../../assets/home/right-arrow-white.png';
import "./Banner.scss";
import { useTranslation} from 'react-i18next';
    
const Banner = (props) => {
    const { t, i18n } = useTranslation();
  return (
    <div className={`main-banner-contents ${props.className}`}>
            <div className="container">
                <div className="banner-wrap">
                    <div className="banner-header">
                        <div className="banner-header-content">
                            <span className='banner-span'>{props.meta}</span>
                            <Link to='/' className='banner-top-btn'>
                                <img src={props.auto} alt="auto" />
                                <span>{props.metabtn}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="banner-content">
                        <div className="left-sec">
                            <div className="left-content">
                                <h1 className='title'>{props.title}</h1>
                                <div className="description">
                                    <p>
                                        {props.description1}
                                    </p>
                                    <p>
                                        {props.description2}
                                    </p>
                                </div>
                                <div className="site-points">
                                    <ul>
                                        {
                                            props.sitepoint?.map((item, id) => (
                                                <li key={id}>{item}</li>
                                            ))
                                        }
                                    </ul>
                                    <ul>
                                        {
                                            props.newsitepoint?.map((items, id) => (
                                                <li key={id}>{items}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="buttons-sec">
                                    <Link to="#" className="sale-btn">
                                        <span>{props.demo}</span>
                                    </Link>
                                    <Link to="#" className="contact-btn">
                                        <span>{props.contactbtn}</span>
                                    </Link>
                                </div>
                                <div className="commitment">
                                    <p>
                                        {props.commit}
                                        <Link href="/" className='get-link'>{props.getlink}</Link>
                                        {props.trial}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="right-sec">
                            <div className="phone-img">
                                <img src={props.bannerimg} alt={props.bannerimg} />
                            </div>
                            <div className="bg-home-img">
                                <img src={props.bannerhomeimg} alt={props.bannerhomeimg} />
                            </div>
                            <div className="customer-stories-banner-img">
                                <div className="content-tab" style={{ backgroundImage: `url(${storybanner})`}}>
                                    <div className="tab-pane">
                                        <div className="top-content">
                                            <div className="tab-logo">
                                                <img src={tabslogo} alt="tabslogo" />
                                            </div>
                                            <p>Crown Heights, NY</p>
                                            <span>300+ visitors</span>
                                        </div>
                                        <div className="bottom-content">
                                            <div className="system-use">
                                                <p>Systems used:</p>
                                            </div>
                                            <div className="link-btn">
                                                <Link>
                                                    <span>{t("CtaRead")}</span>
                                                    <img src={rightarrowwhite} alt="rightarrowwhite" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Banner